import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { messages } from '../messages';

interface TableFiltersModalFooterProps {
  count: number;
  disabledResetAll: boolean;
  onApply: () => void;
  onReset: () => void;
}

export const TableFiltersModalFooter = ({
  onApply,
  onReset,
  count,
  disabledResetAll,
}: TableFiltersModalFooterProps) => (
  <div className="sticky bottom-0 flex items-center justify-between px-6 py-5 bg-grey-50 border-t border-gray-200 text-primary">
    <MuiButton
      variant="outlined"
      color="inherit"
      size="large"
      disabled={disabledResetAll}
      onClick={onReset}
    >
      {messages.resetAll}
    </MuiButton>
    <MuiButton
      variant="contained"
      color="primary"
      size="large"
      onClick={onApply}
    >
      {messages.show}
      <span className="pl-1">({count})</span>
    </MuiButton>
  </div>
);
