import { useMemo } from 'react';
import { Done } from '@mui/icons-material';
import { messages } from 'components/PasswordValidation/messages';
import { Typography } from '@mui/material';
import { PasswordValidationCheckmark } from 'components/PasswordValidation/PasswordValidation.styled';

interface PasswordValidationProps {
  password: string;
}

interface PasswordValidationItemProps {
  messageKey: string;
  active: boolean;
}

const REGEXP_MINCHARACTERS = /[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}/;
const REGEXP_ONELETTER = /(?=.*[a-z])/;
const REGEXP_ONEUPPERCASE = /(?=.*[A-Z])/;
const REGEXP_ONESPECIALCHARACTER = /(?=.*[-'/`~!#*$@_%+=.,^&(){}[\]|;:"<>?\\])/;
const REGEXP_ONENUMBER = /(?=.*[0-9])/;

export const REGEXP_PASSWORD_VALIDATION =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[-'/`~!#*$@_%+=.,^&(){}[\]|;:"<>?\\]).{8,32}$/;

const PasswordValidationItem = ({ messageKey, active }: PasswordValidationItemProps) => (
  <Typography
    variant="caption"
    color={active ? 'success.dark' : undefined}
    component="div"
    className="mt-1 flex items-center"
  >
    <PasswordValidationCheckmark active={active}>
      <Done />
    </PasswordValidationCheckmark>
    <span className="ml-2">{messages[messageKey]}</span>
  </Typography>
);

export const PasswordValidation = ({ password = '' }: PasswordValidationProps) => {
  const keys = ['minCharacters', 'oneLetter', 'oneUppercase', 'oneSpecialCharacter', 'oneNumber'];

  const validationValues = useMemo(() => {
    return {
      minCharacters: REGEXP_MINCHARACTERS.test(password),
      oneLetter: REGEXP_ONELETTER.test(password),
      oneUppercase: REGEXP_ONEUPPERCASE.test(password),
      oneSpecialCharacter: REGEXP_ONESPECIALCHARACTER.test(password),
      oneNumber: REGEXP_ONENUMBER.test(password),
    };
  }, [password]);

  return (
    <>
      {keys.map((key) => (
        <PasswordValidationItem
          messageKey={key}
          key={key}
          active={validationValues[key]}
        />
      ))}
    </>
  );
};
