import { LogoEveAnalyticsIcon } from 'icons';
import { PaymentsPlansCard } from 'features/payments/components/PaymentsPlans/PaymentsPlansCard';
import { PaymentsPlansStyled } from 'features/payments/components/PaymentsPlans/PaymentsPlans.styled';
import { Typography } from '@mui/material';
import { messages } from 'features/payments/messages';
import { PAYMENTS_PLAN, PaymentsSubscriptionType } from 'features/payments/constants';

interface PaymentsPlansProps {
  onClick?: (paymentType: PaymentsSubscriptionType) => void;
  enableRoutingOnButtons?: boolean;
}

export const PaymentsPlans = ({ onClick, enableRoutingOnButtons = false }: PaymentsPlansProps) => {
  return (
    <PaymentsPlansStyled className="min-h-full flex flex-1 justify-center px-8 ">
      <div>
        <div className="flex items-center flex-col mb-8 mt-14">
          <LogoEveAnalyticsIcon width={161} />

          <div className="mt-4 mb-4">
            <Typography variant="h4">{messages.plans.chooseAPlan}</Typography>
          </div>
        </div>
        <div className="flex gap-10 items-center">
          {PAYMENTS_PLAN.map((plan) => (
            <PaymentsPlansCard
              key={plan.type}
              plan={plan}
              onClick={onClick}
              enableRoutingOnButtons={enableRoutingOnButtons}
            />
          ))}
        </div>
      </div>
    </PaymentsPlansStyled>
  );
};
