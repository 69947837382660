import React from 'react';
import { StudyTable } from 'features/studies-table';
import { MetaTitles } from '../shared';
import { Meta } from '../components/Meta';

export const StudyListRoute = () => (
  <>
    <Meta title={MetaTitles.studyList()} />

    <StudyTable />
  </>
);
