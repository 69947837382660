import { FiltersConfig } from './types';

export const getSortedUniqValues = <TColumn>(data: TColumn[], id: string, separator?: string) => {
  const uniqValues = new Set();

  data.forEach((row) => {
    const current = row[id];

    if (separator) {
      current.split(separator).forEach((value) => {
        if (value) uniqValues.add(value);
      });
    } else if (current) uniqValues.add(current);
  });

  return Array.from(uniqValues).sort() as string[];
};

export interface Label {
  [key: string]: string;
}

export const createFullNameLabel = (labels?: Label[]) => (option: string) => {
  const label = labels?.[option]?.fullName;

  return label ? `${label} (${option})` : option;
};

export const getInitialFiltersState = (config: FiltersConfig) =>
  config.reduce(
    (acc, { accessorKey, defaultValue }) => ({
      ...acc,
      [accessorKey]: defaultValue,
    }),
    {}
  );
