import { useEffect, useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { AuthLayout } from 'components/AuthLayout';
import { SignUpComplete } from 'features/sign-up/components/SignUpComplete';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import { Button } from 'components/Button';
import { Link, useSearchParams } from 'react-router-dom';
import { AppRoutes, Config } from 'shared';
import { SignUpQueryKeys } from 'features/sign-up/constants';
import { messages } from '../messages';

const awsConfig = () => {
  return Amplify.configure({
    Auth: {
      region: Config.aws.region, // your AWS region
      userPoolId: Config.aws.userPoolId, // your Cognito User Pool ID
      userPoolWebClientId: Config.aws.userPoolWebClientId, // your Cognito User Pool Client ID
    },
  });
};

export const SignUpEmailVerificationComplete = () => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Get specific parameters
    const confirmationCode = searchParams.get(SignUpQueryKeys.CONFIRMATION_CODE);
    const userName = searchParams.get(SignUpQueryKeys.USER_NAME);

    if (userName && confirmationCode && !loaded) {
      awsConfig();
      Auth.confirmSignUp(userName, confirmationCode)
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setLoaded(true);
        });
    }
  }, []);

  if (!loaded) {
    return (
      <AuthLayout>
        <SignUpComplete
          title={messages.emailVerification.loading}
          description=""
        />
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      {!error ? (
        <SignUpComplete
          title={messages.emailVerification.title}
          description={messages.emailVerification.description}
          icon={
            <CheckCircleOutlinedIcon
              className="text-[2rem] w-8"
              color="success"
            />
          }
          button={
            <Button
              type="button"
              color="primary"
              variant="contained"
              fullWidth
              component={Link}
              to={AppRoutes.signIn()}
              size="large"
              label={messages.emailVerification.button}
            />
          }
        />
      ) : (
        <SignUpComplete
          title={messages.emailVerification.error}
          description={error}
          icon={
            <ErrorIcon
              className="text-[2rem] w-8"
              color="error"
            />
          }
          button={
            <Button
              type="button"
              color="primary"
              variant="contained"
              fullWidth
              component={Link}
              to={AppRoutes.signIn()}
              size="large"
              label={messages.emailVerification.button}
            />
          }
        />
      )}
    </AuthLayout>
  );
};
