import React from 'react';
import { FormControlProps, TextFieldProps } from '@mui/material';
import { SearchInput } from '../SearchInput';

interface SearchFilterProps {
  width: number;
  name: string;
  label: string;
  background?: string;
  placeholder?: string;
  borderRadius?: string | number;
  inputProps?: TextFieldProps;
  controlProps?: FormControlProps;
}

export const SearchFilter = ({
  name,
  width,
  label,
  background,
  placeholder,
  borderRadius,
  controlProps,
  inputProps,
}: SearchFilterProps) => (
  <SearchInput
    key={name}
    name={name}
    placeholder={placeholder}
    label={label}
    controlProps={{
      sx: { ...controlProps?.sx, width, ...controlProps },
    }}
    inputProps={{
      ...inputProps,
      InputProps: {
        style: {
          ...(background ? { background } : {}),
          ...(borderRadius ? { borderRadius } : {}),
        },
      },
      InputLabelProps: { shrink: true },
    }}
  />
);
