import { AppRoutes } from 'shared';
import { messages } from './messages';

export const FOOTER_LINKS = [
  {
    title: messages.help,
    url: AppRoutes.help(),
    shouldOpenNewTab: true,
  },
  {
    title: messages.feedback,
    url: AppRoutes.feedback(),
    shouldOpenNewTab: true,
  },
  {
    title: messages.termsConditions,
    url: AppRoutes.termsConditions(),
  },
];
