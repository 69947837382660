import React, { useEffect, useMemo, useState } from 'react';
import { ColumnDef, getFilteredRowModel } from '@tanstack/react-table';
import { Box as MuiBox } from '@mui/material';
import { useTable, useQuery } from 'hooks';
import { Table } from 'components/Table';
import { TableSearch } from 'components/TableSearch';
import { TableFilters } from 'components/TableFilters';
import { palette } from 'theme';
import { useGetStudiesQuery } from './study-table-api';
import { messages } from './messages';
import { getTableConfig, filtersConfig } from './configs';
import { DEFAULT_SEARCH_STUDY_KEY } from './constants';
import { StudyEntity } from './types';
import { StudiesTableHeader } from './components/StudiesTableHeader';

export const StudyTable = () => {
  const { data: initialData = [], isLoading } = useGetStudiesQuery();

  const columns = useMemo<ColumnDef<StudyEntity, never>[]>(getTableConfig, []);

  const [data, setData] = useState<StudyEntity[]>(initialData);

  // Search query params
  const { query, updateQuery } = useQuery();
  const searchQuery = query.get(DEFAULT_SEARCH_STUDY_KEY) || '';

  // Global Search
  const [searchValue, setSearchValue] = useState(searchQuery);
  const handleSearchValue = (value: string) => setSearchValue(value);

  const table = useTable({
    data,
    columns,
    tableOptions: {
      state: {
        globalFilter: searchValue, // Search value
      },
      onGlobalFilterChange: setSearchValue, // Set search handler
      getFilteredRowModel: getFilteredRowModel(), // Apply search filtering
    },
  });

  useEffect(() => updateQuery({ [DEFAULT_SEARCH_STUDY_KEY]: searchValue }), [searchValue]);

  return (
    <div className="page-container pb-14">
      <StudiesTableHeader />
      <MuiBox
        bgcolor="background.paper"
        sx={{
          width: '100%',
          boxShadow: 1,
          border: `1px solid ${palette.divider}`,
          borderRadius: 2,
        }}
      >
        <div className="flex justify-between px-3 py-4 border-b-[1px]">
          <TableFilters
            initialData={initialData}
            config={filtersConfig}
            setData={setData}
          />
          <TableSearch
            value={searchValue}
            onChange={handleSearchValue}
            placeholder={messages.search}
            sx={{ width: 220 }}
          />
        </div>
        <Table
          instance={table}
          isLoading={isLoading}
        />
      </MuiBox>
    </div>
  );
};
