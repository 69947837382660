import React from 'react';
import {
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableBodyProps as MuiTableBodyProps,
} from '@mui/material';
import { Loading } from 'components/Loading';

interface TableBodyLoadingProps extends MuiTableBodyProps {
  colSpan: number;
}

export const TableBodyLoading = ({ colSpan, ...props }: TableBodyLoadingProps) => (
  <MuiTableBody {...props}>
    <MuiTableRow>
      <MuiTableCell colSpan={colSpan}>
        <span className="absolute top-28 w-full text-center">
          <Loading
            size={40}
            label="Loading"
          />
        </span>
      </MuiTableCell>
    </MuiTableRow>
  </MuiTableBody>
);
