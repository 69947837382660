import { Meta } from 'components/Meta';
import { MetaTitles } from 'shared';
import { HomeSearch } from 'features/home-search';

export const HomeRoute = () => (
  <>
    <Meta title={MetaTitles.home()} />

    <HomeSearch />
  </>
);
