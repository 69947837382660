import { ReactNode, ForwardedRef, forwardRef } from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';

interface ButtonProps extends MuiButtonProps {
  label: ReactNode;
  textTransform?: boolean;
  to?: string;
}

export const Button = forwardRef(
  (
    { label, textTransform = true, ...props }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => (
    <MuiButton
      {...props}
      ref={ref}
      sx={{ ...props?.sx, textTransform: textTransform ? 'uppercase' : 'none' }}
    >
      {label}
    </MuiButton>
  )
);
