import { Typography } from '@mui/material';
import { InputText } from 'components/InputText';
import { Button } from 'components/Button';
import { FormEventHandler } from 'react';
import { messages } from '../messages';

type SignUpContactInfoProps = {
  onSubmit: FormEventHandler<HTMLFormElement>;
  isValid: boolean;
};

export const SignUpContactInfo = ({ onSubmit: handleSubmit, isValid }: SignUpContactInfoProps) => {
  return (
    <form
      className="mt-[12.75rem] flex flex-col"
      onSubmit={handleSubmit}
      noValidate
    >
      <div className="flex flex-col justify-center basis-4/5 gap-10">
        <Typography variant="h4">{messages.contactForm}</Typography>
        <div className="flex flex-col gap-8">
          <InputText
            name="organizationName"
            label={messages.organizationName}
            size="small"
            required
            fullWidth
          />
          <InputText
            name="email"
            label={messages.email}
            placeholder={messages.workEmailPlaceholder}
            size="small"
            required
            fullWidth
          />
          <InputText
            name="message"
            label={messages.message}
            placeholder={messages.writeMessage}
            rows={3}
            required
            fullWidth
            multiline
          />
        </div>
        <Button
          type="submit"
          variant="contained"
          label={messages.send}
          disabled={!isValid}
          sx={{
            paddingTop: '8px ',
            paddingBottom: '8px',
          }}
        />
      </div>
    </form>
  );
};
