import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from 'components/Button';
import { InputText } from 'components/InputText';
import { InputPassword } from 'components/InputPassword';
import { SignUpFormLayout } from 'features/sign-up/components/SignUpFormLayout';
import { messages } from 'features/sign-up/messages';
import { PasswordValidation } from 'components/PasswordValidation/PasswordValidation';
import { useEffect, useState } from 'react';
import { SIGN_UP_MAX_STEP, SignUpTrialStep } from 'features/sign-up/constants';

interface SignUpTrialStep2Props {
  onSubmit: () => void;
  onClickBack: () => void;
}

export const SignUpAccountInfo = ({ onSubmit, onClickBack }: SignUpTrialStep2Props) => {
  const [passwordValidation, showPasswordValidation] = useState(false);

  const { password, passwordConfirmation } = useWatch();
  const { trigger } = useFormContext();

  useEffect(() => {
    if (passwordConfirmation) {
      trigger(['passwordConfirmation']);
    }
  }, [password]);

  return (
    <SignUpFormLayout
      step={SignUpTrialStep.ACCOUNT_INFO}
      maxStep={SIGN_UP_MAX_STEP}
      title={messages.step2.title}
      onSubmit={onSubmit}
      footer={
        <>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            label={messages.step2.submit}
          />

          <div className="mt-4">
            <Button
              type="button"
              fullWidth
              label={messages.step2.back}
              size="large"
              variant="outlined"
              onClick={onClickBack}
            />
          </div>
        </>
      }
    >
      <div>
        <div className="mb-7">
          <InputText
            name="username"
            label={messages.fields.username}
            required
            size="small"
            fullWidth
          />
        </div>

        <div className="mb-7">
          <InputText
            name="firstName"
            label={messages.fields.firstName}
            required
            size="small"
            fullWidth
          />
        </div>

        <div className="mb-7">
          <InputText
            name="lastName"
            label={messages.fields.lastName}
            required
            size="small"
            fullWidth
          />
        </div>

        <div className="mb-7">
          <InputText
            name="email"
            label={messages.fields.workEmail}
            placeholder={messages.fields.workEmailPlaceholder}
            helperText={messages.fields.workEmailHelperText}
            required
            size="small"
            fullWidth
          />
        </div>

        <div className="mb-7">
          <InputPassword
            onFocus={() => showPasswordValidation(true)}
            onBlur={() => showPasswordValidation(false)}
            name="password"
            label={messages.fields.password}
            required
            size="small"
            fullWidth
          />

          {passwordValidation && (
            <div className="mt-2">
              <PasswordValidation password={password} />
            </div>
          )}
        </div>

        <div className="mb-7">
          <InputPassword
            name="passwordConfirmation"
            helperText={messages.fields.repeatPasswordHelperText}
            label={messages.fields.repeatPassword}
            required
            size="small"
            fullWidth
          />
        </div>
      </div>
    </SignUpFormLayout>
  );
};
