import * as yup from 'yup';
import { REGEXP_PASSWORD_VALIDATION } from 'components/PasswordValidation';
import { messages } from 'features/sign-up/messages';
import { EMAIL_REGEX } from 'shared';

export const signUpOrganizationInfoSchema = yup.object().shape({
  organizationType: yup.string().required(messages.errors.required),
  institution: yup.string().required(messages.errors.required),
  organization: yup.string().required(messages.errors.required),
});

export const signUpAccountInfoSchema = yup.object().shape({
  email: yup
    .string()
    .required(messages.errors.required)
    .matches(EMAIL_REGEX, { message: messages.errors.invalidEmail }),
  username: yup.string().required(messages.errors.required),
  firstName: yup.string().required(messages.errors.required),
  lastName: yup.string().required(messages.errors.required),
  password: yup
    .string()
    .required(messages.errors.required)
    .matches(REGEXP_PASSWORD_VALIDATION, messages.errors.invalidPassword),
  passwordConfirmation: yup
    .string()
    .required(messages.errors.required)
    .oneOf([yup.ref('password')], messages.errors.invalidPasswordConfirmation),
});
