import { SerializedError } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

export type RouteHandle = {
  crumb?: () => ReactNode;
};

export type RequestError = SerializedError | { status?: number; data: {} };

export enum LevelAccesses {
  TRIAL = 'trial',
  LICENSE = 'license',
}

export enum SubscriptionPlans {
  TRIAL = 'trial',
  LICENSE = 'license',
}

export enum ContactFormRecipient {
  SALES = 'sales',
  SUPPORT = 'support',
}
