import { ReactNode, createContext, useMemo } from 'react';
import { LevelAccesses, RequestError } from 'shared';
import { useProfileApiFetchMe } from './profile-api';
import { ProfileEntity } from './types';

type ProfileContextType = {
  isLoading: boolean;
  isSuccess: boolean;
  data: ProfileEntity;
  error?: RequestError;
  refetch: () => void;
};

type ProfileProviderProps = {
  children: ReactNode;
};

export const ProfileContext = createContext<ProfileContextType>({} as ProfileContextType);

export const ProfileProvider = ({ children }: ProfileProviderProps) => {
  const { error, data, isLoading, isSuccess, refetch } = useProfileApiFetchMe();

  const contextValue = useMemo(
    () => ({
      error,
      // TODO: refactor when role is implemented on BE side
      data: { ...data, role: LevelAccesses.LICENSE } as ProfileEntity,
      isLoading,
      isSuccess,
      refetch,
    }),
    [error, data, isLoading, isSuccess, refetch]
  );

  return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>;
};
