export enum StudyTypes {
  VIEW = 'View',
  EDIT = 'Edit',
}

export enum TYPES {
  EFF = 'EFF',
  TOX = 'TOX',
  CC = 'CC',
  OMICS = 'OMICS',
  VIVO = 'VIVO',
  DM = 'DM',
  PK = 'PK',
}

export type StudyEntity = {
  organs: string;
  name: string;
  id: number;
  studyTypeDisplay: string;
  experimentalModels: string;
  reproNums: string;
  dataPointsCount: number;
  omicData2fcPointsCount: number;
  imageCount: number;
  startDate: string;
  videoCount: number;

  // User Permission
  userPermission: StudyTypes;

  // Links
  omicsLink: string;
  imagesLink: string;
  summaryLink: string;
  overviewLink: string;
  reproducibilityLink: string;
};
