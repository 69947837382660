import React from 'react';
import { GlobalSearch } from 'components/GlobalSearch';
import { useGetStudiesQuery } from 'features/studies-table/study-table-api';
import { AppRoutes } from 'shared';
import { searchConfig, SearchGroups } from './configs';

export const SearchStudies = () => {
  const { data = [] } = useGetStudiesQuery();

  return (
    <GlobalSearch
      data={data}
      config={searchConfig}
      groups={SearchGroups}
      pathname={AppRoutes.studyList()}
    />
  );
};
