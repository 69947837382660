import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Loading } from 'components/Loading';
import { getLegacyRoutePath } from 'utils/get-legacy-route-path';
import { messages } from 'shared';

export const RedirectToLegacyApp = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.location.replace(getLegacyRoutePath(pathname));
  }, [pathname]);

  return <Loading label={messages.loading} />;
};
