import { useAuth } from 'common/auth';
import { Layout } from 'components/Layout';
import { Navigate } from 'react-router-dom';
import { AppRoutes } from 'shared';

export const PrivateRoute = () => {
  const { isAuthorized } = useAuth();

  if (!isAuthorized) {
    return (
      <Navigate
        to={AppRoutes.signIn()}
        replace
      />
    );
  }

  return <Layout isPrivate />;
};
