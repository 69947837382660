import React, { useState } from 'react';
import {
  InputAdornment as MuiInputAdornment,
  TextField as MuiTextField,
  AutocompleteRenderInputParams,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { messages } from '../messages';

interface SearchableAutocompleteInputProps extends AutocompleteRenderInputParams {
  label: string;
}

export const SearchableAutocompleteInput = ({
  label,
  ...params
}: SearchableAutocompleteInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);

  const handleBlur = () => setIsFocused(false);

  return (
    <MuiTextField
      {...params}
      type="search"
      onBlur={handleBlur}
      onFocus={handleFocus}
      label={isFocused ? label : `${messages.select} ${label}`}
      InputProps={{
        ...params.InputProps,
        ...(isFocused
          ? {
              startAdornment: (
                <MuiInputAdornment position="start">
                  <Search />
                </MuiInputAdornment>
              ),
            }
          : {}),
      }}
    />
  );
};
