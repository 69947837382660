import { CircularProgress, Typography } from '@mui/material';

type LoadingProps = {
  label?: string;
  size?: number;
};

export const Loading = ({ label, size = 60 }: LoadingProps) => (
  <div className="h-full flex flex-col justify-center gap-2 items-center">
    <CircularProgress size={size} />
    {label && (
      <Typography
        variant="subtitle2"
        color="text.secondary"
      >
        {label}
      </Typography>
    )}
  </div>
);
