import { Typography } from '@mui/material';
import { messages } from 'features/payments/messages';

import { PaymentsPlanEntity } from 'features/payments/types';
import { PaymentsPlanButton } from 'features/payments/components/PaymentsPlans/PaymentsPlans.styled';

type PaymentPlanButton = {
  plan: PaymentsPlanEntity;
  isAnnualActive: boolean;
  onChange: (value: boolean) => void;
};

export const PaymentsPlansSwitch = ({ plan, isAnnualActive, onChange }: PaymentPlanButton) => {
  const { priceCurrency, price, priceAnnual } = plan;

  return (
    <>
      <div className="flex bg-grey-100 p-1 mt-9 rounded-lg">
        <PaymentsPlanButton
          active={!isAnnualActive}
          onClick={() => onChange(false)}
        >
          {messages.plans.monthly}
        </PaymentsPlanButton>

        <PaymentsPlanButton
          active={isAnnualActive}
          onClick={() => onChange(true)}
        >
          {messages.plans.annual}
          <Typography
            variant="caption"
            className="ml-1 mt-1"
          >
            {messages.plans.annualSave}
          </Typography>
        </PaymentsPlanButton>
      </div>

      <div className="mt-8">
        <Typography
          variant="body1"
          color="rgba(0, 0, 0, 0.60)"
        >
          <Typography
            variant="h3"
            color="black"
            component="span"
          >
            {isAnnualActive ? priceAnnual : price}
            {priceCurrency}
          </Typography>
          /{messages.plans.perSeat}
        </Typography>
      </div>
    </>
  );
};
