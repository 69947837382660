import { useEffect, useRef, useState } from 'react';

export const useTruncateText = () => {
  const containerRef = useRef<HTMLSpanElement>(null);
  const [isTruncatedText, setIsTruncatedText] = useState<boolean>(false);

  useEffect(() => {
    const compareSize = () => {
      const container = containerRef?.current;

      if (container) {
        const isVerticalTruncated = container?.scrollHeight > container?.clientHeight;
        const isHorizontalTruncated = container?.scrollWidth > container?.clientWidth;
        setIsTruncatedText(isVerticalTruncated || isHorizontalTruncated);
      }
    };

    compareSize();
    window.addEventListener('resize', compareSize);

    return () => window.removeEventListener('resize', compareSize);
  }, []);

  return { isTruncatedText, containerRef };
};
