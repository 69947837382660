import React, { ReactNode } from 'react';
import { TableCell as MUITableCell, TableCellProps } from '@mui/material';
import styled from '@emotion/styled';
import { palette } from 'theme';

interface TableCellWrapperProps extends TableCellProps {
  minWidth?: number;
  children: ReactNode;
  shadow: boolean;
}

const TableCellWrapperStyled = styled(MUITableCell)<{ shadow: number }>`
  box-shadow: ${({ shadow }) => shadow && '2px 0 4px -2px rgba(0, 0, 0, 0.21)'};
`;

export const TableCellWrapper = ({
  className,
  minWidth,
  children,
  shadow,
  ...props
}: TableCellWrapperProps) => (
  <TableCellWrapperStyled
    className={className}
    shadow={+shadow}
    sx={{
      minWidth,
      borderBottom: `1px solid ${palette.divider}`,
    }}
    {...props}
  >
    {children}
  </TableCellWrapperStyled>
);
