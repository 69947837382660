import React, { MouseEvent } from 'react';
import { IconButton as MUIIconButton, IconButtonProps as MUIIconButtonProps } from '@mui/material';
import { VerticalThreeDotsIcon } from 'icons';
import classNames from 'classnames';
import { palette } from 'theme';
import { messages } from '../constants/messages';

export interface ActionButtonProps extends MUIIconButtonProps {
  a11yRowActions?: string;
}

interface TableCellActionsButtonProps extends ActionButtonProps {
  isOpen: boolean;
  anchorClassName?: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const ActionsButton = ({
  isOpen,
  onClick,
  anchorClassName,
  a11yRowActions = messages.a11yRowActions,
  ...props
}: TableCellActionsButtonProps) => (
  <MUIIconButton
    disableFocusRipple
    aria-label={a11yRowActions}
    color="default"
    sx={{
      border: `1px solid ${palette.divider}`,
      borderRadius: 2,
      boxShadow: 1,
      width: 40,
      height: 40,
      backgroundColor: isOpen ? palette.custom.button.focus : palette.background.default,
      '&:hover, &:active, &:focus': {
        backgroundColor: palette.action.hover,
      },
    }}
    {...props}
    onClick={onClick}
    className={classNames(anchorClassName, { visible: isOpen })}
  >
    <VerticalThreeDotsIcon />
  </MUIIconButton>
);
