import { Link as MUILink, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from 'components/Button';
import { AppRoutes, Config } from 'shared';
import { AuthLayout } from 'components/AuthLayout';
import { messages } from './messages';

export const RecoveryUsername = () => {
  return (
    <AuthLayout>
      <div className="mt-[12.75rem]">
        <Typography variant="h4">{messages.recoverUsername}</Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          className="mt-4"
        >
          {messages.contactUs}
          <MUILink
            href={`mailto:${Config.supportEmail}`}
            underline="hover"
            display="inline-block"
          >
            {Config.supportEmail}
          </MUILink>
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ marginTop: '2.5rem', marginBottom: '2.5rem' }}
        >
          {messages.getBackSoon}
        </Typography>
        <MUILink
          component={RouterLink}
          to={AppRoutes.signIn()}
        >
          <Button
            label={messages.back}
            variant="contained"
            size="large"
            fullWidth
          />
        </MUILink>
      </div>
    </AuthLayout>
  );
};
