import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { stringifySearchParams } from 'utils/search-params';
import { getInitialFiltersState, FiltersConfig, Filters } from 'components/FilterUtils';
import { Button } from '../Button';
import { messages } from './messages';
import { getGroups, Groups } from './utils';

interface StudiesSearchProps<TColumn> {
  data: TColumn[];
  pathname: string;
  groups: Groups;
  config: FiltersConfig;
}

export const GlobalSearch = <TColumn,>({
  data,
  config,
  groups,
  pathname,
}: StudiesSearchProps<TColumn>) => {
  const navigate = useNavigate();

  const initialFilters = useMemo(() => getInitialFiltersState(config), [config]);
  const [searchFields, ...otherGroups] = useMemo(() => getGroups(groups, config), [groups, config]);

  const form = useForm({ defaultValues: initialFilters });

  const handleSearch = (updatedFilters: Filters) => {
    navigate({ pathname, search: stringifySearchParams(updatedFilters) });
  };

  return (
    <FormProvider {...form}>
      <form className="h-full flex flex-col justify-center items-center">
        <div>
          <div className="flex">
            {searchFields.map(({ accessorKey, FilterComponent, width, maxWidth }) =>
              FilterComponent({
                key: accessorKey,
                name: accessorKey,
                data,
                width,
                maxWidth,
              })
            )}
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={form.handleSubmit(handleSearch)}
              label={messages.search}
              sx={{
                paddingX: 4.5,
                paddingY: 1.83,
                borderRadius: '0 8px 8px 0',
              }}
            />
          </div>
          {otherGroups.map((group, i) => (
            <div
              className="flex pt-2"
              key={Object.keys(groups)[i + 1]}
            >
              {group.map(({ accessorKey, FilterComponent }) =>
                FilterComponent({
                  key: accessorKey,
                  name: accessorKey,
                  data,
                })
              )}
            </div>
          ))}
        </div>
      </form>
    </FormProvider>
  );
};
