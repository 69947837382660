import { Link as RouterLink } from 'react-router-dom';
import { AppRoutes } from 'shared';
import { LogoEveAnalyticsIcon } from 'icons';
import { Dropdown } from './components/Dropdown';
import { NAVIGATION_OPTIONS } from './constants';
import { messages } from './messages';
import { ButtonStyled } from './styled';
import { UsernameMenu } from './components/UsernameMenu';

export const Navbar = () => {
  const openNewWindow = (path: string, target: string) => {
    window.open(
      path,
      target,
      'toolbars=0,width=1300,height=850,left=200,top=200,scrollbars=1,resizable=1'
    );
  };

  return (
    <nav className="sticky top-0 py-2 bg-grey-50 border-b border-grey-250 z-[999]">
      <div className="page-container flex items-center justify-between">
        <div className="flex items-center">
          <RouterLink to={AppRoutes.root()}>
            <LogoEveAnalyticsIcon
              width={138}
              height={20}
            />
          </RouterLink>
          <ul className="flex ml-[58px]">
            {NAVIGATION_OPTIONS.map((section) => (
              <li
                key={section.title}
                className="mr-2"
              >
                <Dropdown
                  title={section.title}
                  groups={section.groups}
                />
              </li>
            ))}
            <li className="mr-2">
              <ButtonStyled
                label={messages.help}
                textTransform={false}
                withIcon={false}
                disableTouchRipple
                onClick={() => openNewWindow(AppRoutes.help(), 'help')}
              />
            </li>
            <li>
              <ButtonStyled
                label={messages.feedback}
                textTransform={false}
                withIcon={false}
                disableTouchRipple
                onClick={() => openNewWindow(AppRoutes.feedback(), 'feedback')}
              />
            </li>
          </ul>
        </div>
        <div className="flex basis-1/6 flex-row-reverse">
          <UsernameMenu />
        </div>
      </div>
    </nav>
  );
};
