import React from 'react';
import classNames from 'classnames';
import { FormControlLabelProps, CheckboxProps, Typography as MuiTypography } from '@mui/material';
import { Checkbox } from '../Checkbox';

interface CheckboxFilterClasses {
  title?: string;
  checkbox?: string;
}

interface CheckboxFilterProps {
  name: string;
  label: string;
  title?: string;
  classes?: CheckboxFilterClasses;
  labelProps?: FormControlLabelProps;
  checkboxProps?: CheckboxProps;
}

export const CheckboxFilter = ({
  name,
  label,
  title,
  classes,
  labelProps,
  checkboxProps,
}: CheckboxFilterProps) => (
  <>
    {title && (
      <MuiTypography
        className={classes?.title ? classes?.title : 'px-6 mt-4 mb-1.5'}
        variant="body2"
        fontWeight="medium"
        color="text.primary"
      >
        {title}
      </MuiTypography>
    )}
    <Checkbox
      {...checkboxProps}
      key={name}
      name={name}
      sx={{ m: 0 }}
      label={label}
      typographyProps={{ variant: 'body1', color: 'text.primary' }}
      labelProps={
        {
          className: classNames(classes?.checkbox),
          ...labelProps,
        } as FormControlLabelProps
      }
    />
  </>
);
