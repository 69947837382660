import React from 'react';
import {
  Table as MuiTable,
  TableProps as MuiTableProps,
  TableContainer as MuiTableContainer,
  TableContainerProps as MuiTableContainerProps,
} from '@mui/material';
import { Table as ReactTableProps } from '@tanstack/react-table';
import { DefaultTableBodyProps, TableBody } from '../TableBody';
import { TableHead, DefaultTableHeadProps } from '../TableHead';
import {
  Messages as PaginationMessages,
  TablePagination,
  TablePaginationProps,
} from '../TablePagination';
import { DEFAULT_STICKY_OPTIONS, DEFAULT_TABLE_OPTIONS } from './constants';
import { messages as defaultMessages } from './messages';

interface Messages extends PaginationMessages {
  a11yTable: string;
}

interface TableProps<TColumn> extends MuiTableProps {
  instance: ReactTableProps<TColumn>;
  isLoading: boolean;
  stickyOptions?: {
    stickyHeader?: boolean;
    stickyFirstColumn?: boolean;
    stickyLastColumn?: boolean;
  };
  options?: {
    cellHeight?: string | number;
    tableHeight?: string | number;
    tableMinWidth?: string | number;
    withoutPagination?: boolean;
    anchorActionsClassName?: string;
  };
  messages?: Messages;
  tableContainerProps?: MuiTableContainerProps;
  tableHeadProps?: DefaultTableHeadProps;
  tableBodyProps?: DefaultTableBodyProps;
  tablePaginationProps?: TablePaginationProps;
}

const getAnchorActionClasses = (className: string) => ({
  /*
   *  Table actions button className configurable - for covering visibility - by default .table-actions
   */
  [`& .MuiTableRow-root .${className}`]: {
    visibility: 'hidden',
  },
  /*
   *  Visible for hovering, focusing from keyboard anything into table, focusing actions button
   */
  [`#body & .MuiTableRow-root:hover .${className},
    #body & .MuiTableRow-root:focus-visible .${className},
    #body & .MuiTableRow-root:focus-within .${className}`]: {
    visibility: 'visible',
  },
});

export const Table = <TColumn,>({
  instance,
  isLoading,
  stickyOptions = {},
  options = {},
  tableContainerProps,
  tablePaginationProps,
  tableHeadProps,
  tableBodyProps,
  messages,
  ...tableProps
}: TableProps<TColumn>) => {
  const combinedMessages = { ...defaultMessages, ...messages };
  const { stickyHeader, ...sticky } = { ...DEFAULT_STICKY_OPTIONS, ...stickyOptions };
  const { cellHeight, tableHeight, tableMinWidth, withoutPagination, anchorActionsClassName } = {
    ...DEFAULT_TABLE_OPTIONS,
    ...options,
  };

  return (
    <div className="relative">
      <MuiTableContainer
        component="div"
        {...tableContainerProps}
        sx={{
          height: tableHeight,
          ...getAnchorActionClasses(anchorActionsClassName),
        }}
      >
        <MuiTable
          aria-label={combinedMessages.a11yTable}
          {...tableProps}
          stickyHeader={stickyHeader}
          sx={{ minWidth: tableMinWidth }}
        >
          <TableHead
            {...tableHeadProps}
            groups={instance.getHeaderGroups()}
            stickyOptions={sticky}
            cellHeight={cellHeight}
          />
          <TableBody
            {...tableBodyProps}
            isLoading={isLoading}
            columnsCount={instance.getAllColumns().length}
            rows={instance.getRowModel().rows}
            stickyOptions={sticky}
            cellHeight={cellHeight}
            anchorActionsClassName={anchorActionsClassName}
          />
        </MuiTable>
      </MuiTableContainer>
      {!withoutPagination && (
        <TablePagination
          {...tablePaginationProps}
          messages={combinedMessages}
          state={instance.getState().pagination}
          length={instance.getFilteredRowModel().rows.length}
          onPageIndex={instance.setPageIndex}
          onPageSize={instance.setPageSize}
        />
      )}
    </div>
  );
};
