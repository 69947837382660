import * as yup from 'yup';
import { EMAIL_REGEX } from 'shared';
import { messages } from './messages';

export const contactSalesSchema = yup.object().shape({
  organizationName: yup.string().required(messages.required),
  email: yup
    .string()
    .required(messages.required)
    .matches(EMAIL_REGEX, { message: messages.invalidEmail }),
  message: yup.string().required(messages.required),
});
