import React from 'react';
import classNames from 'classnames';
import { Typography as MuiTypography, TypographyProps as MuiTypographyProps } from '@mui/material';

interface EmptyCellProps extends MuiTypographyProps {
  isNumber?: boolean;
}

export const EmptyCell = ({ isNumber, ...props }: EmptyCellProps) => (
  <MuiTypography
    color="text.disabled"
    className={classNames({ 'pr-3': isNumber })}
    {...props}
  >
    {isNumber ? 0 : '-'}
  </MuiTypography>
);
