import { SearchFilter } from './SearchFilter';
import { CheckboxFilter } from './CheckboxFilter';
import { AutocompleteFilter } from './AutocompleteFilter';
import { SearchableAutocompleteFilter } from './SearchableAutocompleteFilter';

export const FilterComponents = {
  SearchFilter,
  CheckboxFilter,
  AutocompleteFilter,
  SearchableAutocompleteFilter,
};
