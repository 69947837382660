import React, { ReactNode, useState } from 'react';
import { Modal as MuiModal } from '@mui/material';
import { useQuery } from 'hooks';
import { FiltersConfig } from 'components/FilterUtils';
import { messages } from '../messages';
import { TableFiltersModalHeader } from './TableFiltersModalHeader';
import { TableFiltersModalFooter } from './TableFiltersModalFooter';
import { TableFiltersButton } from './TableFiltersButton';
import { getAppliedFiltersCount, getFiltersFromQuery } from '../utils';

interface TableFiltersModalProps {
  children: ReactNode;
  filteredDataCount: number;
  disableResetAll: boolean;
  config: FiltersConfig;
  onApply: () => void;
  onReset: () => void;
}

export const TableFiltersModal = ({
  children,
  config,
  onApply,
  onReset,
  filteredDataCount,
  disableResetAll,
}: TableFiltersModalProps) => {
  const { query } = useQuery();
  const count = getAppliedFiltersCount(getFiltersFromQuery(query, config));

  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleApply = () => {
    onApply();
    handleClose();
  };

  return (
    <>
      <TableFiltersButton
        count={count}
        onOpen={handleOpen}
      />
      <MuiModal
        open={open}
        onClose={handleClose}
        aria-label={messages.a11yFilters}
      >
        <form className="absolute top-0 right-0 w-[480px] h-full flex flex-col justify-between bg-white">
          <div className="overflow-auto pb-6">
            <TableFiltersModalHeader onClose={handleClose} />
            {children}
          </div>
          <TableFiltersModalFooter
            onApply={handleApply}
            onReset={onReset}
            count={filteredDataCount}
            disabledResetAll={disableResetAll}
          />
        </form>
      </MuiModal>
    </>
  );
};
