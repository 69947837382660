import { Card, Link, ListItemAvatar, Typography } from '@mui/material';
import { Button } from 'components/Button';
import { Link as RouterLink } from 'react-router-dom';
import { PaymentsPlansSwitch } from 'features/payments/components/PaymentsPlans/PaymentsPlansSwitch';
import { AppRoutes } from 'shared';
import { messages } from 'features/payments/messages';

import { PaymentsPlanEntity } from 'features/payments/types';
import { PaymentsSubscriptionType } from 'features/payments/constants';
import { useState } from 'react';

type PaymentsPlansCardProps = {
  plan: PaymentsPlanEntity;
  onClick?: (type: PaymentsSubscriptionType) => void;
  enableRoutingOnButtons?: boolean;
};

export const PaymentsPlansCard = ({
  plan,
  onClick,
  enableRoutingOnButtons,
}: PaymentsPlansCardProps) => {
  const { route, name, benefits, buttonVariant, buttonText, price, img, priceAnnual } = plan;

  const showPriceSwitcher = priceAnnual && price;
  const [isAnnualActive, setIsAnnualActive] = useState(false);

  const handleChangeAnnualSwitcher = (value: boolean) => setIsAnnualActive(value);

  return (
    <Card
      className="flex flex-col rounded-2xl min-h-[600px] min-w-[372px] max-w-[372px] pt-12"
      elevation={6}
    >
      <div className="px-6 flex flex-col justify-between">
        <h3 className="flex flex-col gap-8 text-center font-merriweather text-[32px]">{name}</h3>

        {img && (
          <div className="w-[220px] mt-6 self-center">
            <img
              src={img}
              className="w-full"
              alt="plan"
            />
          </div>
        )}

        {showPriceSwitcher && (
          <PaymentsPlansSwitch
            onChange={handleChangeAnnualSwitcher}
            isAnnualActive={isAnnualActive}
            plan={plan}
          />
        )}
      </div>

      <div className="p-6 pb-0 pt-9 basis-4/6 flex-1">
        <ul className="p-0">
          {benefits.map((benefit, index) => (
            <li
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              className="mt-2 flex"
            >
              <ListItemAvatar
                className="w-[6px] mt-2 h-[6px] rounded-full min-w-[6px] mr-2"
                sx={{
                  bgcolor: 'primary.main',
                }}
              />
              <Typography variant="body1">{benefit}</Typography>
            </li>
          ))}
        </ul>
      </div>

      {showPriceSwitcher && (
        <div className="px-6 mt-8">
          <Typography
            variant="body2"
            className="text-center px-2"
            color="text.secondary"
          >
            <span>{messages.plans.contactSalesMessage}</span>{' '}
            <Link
              component={RouterLink}
              target="_blank"
              className="no-underline"
              to={AppRoutes.prem()}
            >
              {messages.plans.contactUs}
            </Link>
          </Typography>
        </div>
      )}

      <div className="px-6 pb-7 mt-4 flex justify-center content-center">
        <Button
          fullWidth
          variant={buttonVariant}
          size="large"
          type="button"
          color="primary"
          className="min-h-[56px]"
          label={buttonText}
          onClick={() =>
            onClick && onClick(isAnnualActive ? PaymentsSubscriptionType.ANNUAL : plan.type)
          }
          {...(enableRoutingOnButtons
            ? {
                component: RouterLink,
                to: route,
              }
            : {})}
        />
      </div>
    </Card>
  );
};
