import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import {
  applyAxiosSettings,
  applyAxiosInterceptors,
  applyGlobalErrorHandling,
} from './utils/axios-config';
import { store } from './store';

import './styles/fonts.css';
import './styles/index.css';

import App from './App';

applyAxiosSettings();
applyAxiosInterceptors();
applyGlobalErrorHandling();

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
