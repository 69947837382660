import React from 'react';
import { IconButton as MuiIconButton, Typography as MuiTypography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { messages } from '../messages';

interface TableFiltersModalHeaderProps {
  onClose: () => void;
}

export const TableFiltersModalHeader = ({ onClose }: TableFiltersModalHeaderProps) => (
  <div className="sticky top-0 flex items-center justify-between px-6 py-[11px] bg-grey-50 opacity-100 border-b border-gray-200 z-10">
    <MuiTypography
      color="text.primary"
      variant="h5"
    >
      {messages.filters}
    </MuiTypography>
    <MuiIconButton
      color="default"
      size="small"
      onClick={onClose}
    >
      <Close />
    </MuiIconButton>
  </div>
);
