import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import { messages } from '../messages';

type SignUpCompleteProps = {
  title?: string;
  description?: string;
  icon?: ReactElement;
  button?: ReactElement;
};

export const SignUpComplete = ({
  title = messages.success.title,
  description = messages.success.description,
  icon,
  button,
}: SignUpCompleteProps) => (
  <div className="mt-[12.75rem] flex flex-col justify-center gap-4">
    <div className="flex items-center">
      {icon && <div className="mr-2">{icon}</div>}
      <Typography variant="h4">{title}</Typography>
    </div>
    <Typography
      variant="body1"
      component="p"
      color="text.secondary"
      whiteSpace="pre-wrap"
    >
      {description}
    </Typography>

    {button && <div className="mt-10">{button}</div>}
  </div>
);
