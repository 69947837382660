export const Config = {
  api: {
    baseUrl: process.env.REACT_APP_DJANGO_API_URL!,
    baseUrlNoApi: process.env.REACT_APP_DJANGO_APP_URL!,
  },
  helpPageUrl: process.env.REACT_APP_HELP_PAGE_URL!,
  originUrl: process.env.REACT_APP_DJANGO_APP_URL,
  infoEmail: 'support@eveanalytics.com',
  supportEmail: 'support@eveanalytics.com',
  supportPhone: '+1-973-123-4567',

  aws: {
    userForgotPasswordUrl: `${process.env.REACT_APP_FORGOT_PASSWORD_URL}&redirect_uri=${process.env.REACT_APP_ORIGIN_URL}`,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID!,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID!,
    region: process.env.REACT_APP_AWS_REGION!,
  },

  termsAndConditions: {
    trial: '/',
  },
  shouldHandle302RedirectError: process.env.REACT_APP_SHOULD_HANDLE_302_REDIRECT_ERROR === 'true',
};
