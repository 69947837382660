import { Dialog, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import { Button } from 'components/Button';
import { useNonRemovable } from 'hooks';
import { useRef, useState } from 'react';
import { termsConditionsTemplate } from 'templates/termsConditions';
import { palette } from 'theme';
import sanitize from 'xss';
import { messages } from './messages';

interface TermsConditionsModalProps {
  onClose: () => void;
  onAccept: () => void;
}

export const TermsConditionsModal = ({ onClose, onAccept }: TermsConditionsModalProps) => {
  const [open, setOpen] = useState(true);
  const [bottom, setBottom] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const bottomOffset = 10;

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleScroll = (e: React.BaseSyntheticEvent) => {
    if (!bottom) {
      const isBottom =
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < bottomOffset;
      setBottom(isBottom);
    }
  };

  const handleAccept = () => {
    onAccept();
    setOpen(false);
  };

  useNonRemovable(modalRef, !open);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="md"
      disableEscapeKeyDown
      disablePortal
      ref={modalRef}
    >
      <Typography
        variant="h5"
        className="py-6 px-10"
        whiteSpace="pre-wrap"
      >
        {messages.updatedTerms}
      </Typography>
      <DialogContent
        sx={{ padding: '0 40px 20px 40px' }}
        onScroll={handleScroll}
        onTouchMove={handleScroll}
      >
        <Divider />
        <article
          className="prose prose-h4:text-[2rem] prose-h4:font-normal prose-h4:mb-2 prose-h5:text-2xl prose-h5:font-normal prose-h5:mt-6 prose-h5:mb-4 max-w-none mt-6"
          dangerouslySetInnerHTML={{ __html: sanitize(termsConditionsTemplate) }}
        />
      </DialogContent>
      <DialogActions
        sx={{ padding: '28px 40px', borderTop: '1px solid', borderTopColor: palette.divider }}
      >
        <div className="flex gap-x-6 mx-auto">
          <Button
            size="large"
            variant="outlined"
            label={messages.declineAndLogOut}
            sx={{ width: 240 }}
            onClick={onClose}
          />
          <Button
            size="large"
            variant="contained"
            label={messages.accept}
            sx={{ width: 240 }}
            disabled={!bottom}
            onClick={handleAccept}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};
