import { checkboxFilterFunction } from './checkbox-filter-function';
import { multiSelectFilterFunction } from './multi-select-filter-function';

export * from './utils';
export * from './types';

export const FilterUtils = {
  checkbox: checkboxFilterFunction,
  multiSelect: multiSelectFilterFunction,
};
