import React, { ReactNode } from 'react';
import {
  TruncatedTextTooltipCell,
  TruncatedTextTooltipCellProps,
} from './components/TruncatedTextTooltipCell';
import { EmptyCell } from './components/EmptyCell';

export interface TableCellTextProps extends Omit<TruncatedTextTooltipCellProps, 'children'> {
  value: ReactNode;
}

export const TableCellText = ({ value, ...props }: TableCellTextProps) => {
  if (!value) return <EmptyCell />;

  return (
    <TruncatedTextTooltipCell
      placement="top"
      {...props}
    >
      {value}
    </TruncatedTextTooltipCell>
  );
};
