export const messages = {
  studies: 'Studies',
  studyData: 'Study Data',
  viewEditableStudies: 'View Editable Studies',
  viewAllStudies: 'View All Studies',
  viewStudySets: 'View Study Sets',
  studyComponents: 'Study Components',
  requiresPermission: 'Requires Permission',
  addStudy: 'Add Study',
  addStudySet: 'Add Study Set',
  analysis: 'Analysis',
  studyDataAnalysis: 'Study Data Analysis',
  graphingReproducibility: 'Graphing/Reproducibility',
  compoundActivities: 'Compound Activities',
  heatmap: 'Heatmap',
  cluster: 'Cluster',
  computationalModeling: 'Computational Modeling',
  pbpk: 'PBPK',
  predictiveModeling: 'Predictive Modeling',
  api: 'API',
  webApi: 'Web API',
  models: 'Models',
  modelData: 'Model Data',
  viewExperimentalModels: 'View Experimental Models',
  modelComponents: 'Model Components',
  viewMicrodevices: 'View Microdevices',
  viewMicrodeviceLocations: 'View Microdevice Locations',
  addExperimentalModel: 'Add Experimental Model',
  addMicrodevice: 'Add Microdevice',
  compounds: 'Compounds',
  compoundData: 'Compound Data',
  viewChemicalData: 'View Chemical Data',
  viewBioactivities: 'View Bioactivities',
  viewDrugTrials: 'View Drug Trials',
  viewAdverseEvents: 'View Adverse Events',
  reports: 'Reports',
  compareAdverseEvents: 'Compare Adverse Events',
  compoundReport: 'Compound Report',
  addCompound: 'Add Compound',
  addMultipleCompound: 'Add Multiple Compounds from File',
  diseases: 'Diseases',
  covidPortal: 'Covid-19 Portal',
  viewOtherDiseases: 'View Other Diseases',
  cells: 'Cells',
  cellData: 'Cell Data',
  viewCellTypes: 'View Cell Types',
  viewCellOrigins: 'View Cell Origins',
  viewCellSamples: 'View Cell Samples',
  addCellType: 'Add Cell Type',
  addCellOrigin: 'Add Cell Origin',
  addCellSample: 'Add Cell Sample',
  about: 'About',
  whatsNew: 'What’s New',
  benefits: 'Benefits',
  comingSoon: 'Coming Soon',
  experimentalModels: 'Experimental Models',
  faqs: 'FAQs',
  contactUs: 'Contact Us',
  help: 'Help',
  feedback: 'Feedback',
};
