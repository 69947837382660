import React, { memo, useMemo } from 'react';
import { Link as MuiLink } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { grey } from '@mui/material/colors';
import { EmptyCell } from './components/EmptyCell';

interface ChartValue {
  id: number;
  color: string;
  label: string;
}

interface ChartValues {
  [key: string | number]: ChartValue;
}

interface TableCellChartProps {
  link: string;
  chartValues: ChartValues;
  size?: number;
  value?: string;
}

export const TableCellChart = memo(
  ({ chartValues, value, size = 50, link }: TableCellChartProps) => {
    const numbers = value?.split('|');
    const isInvalid = !value || numbers?.every((number) => !Number(number));

    if (isInvalid) return <EmptyCell />;

    const data = useMemo(
      () => numbers!.map((number, i) => ({ value: Number(number), ...chartValues[i] })),
      [numbers]
    );

    return (
      <MuiLink href={link}>
        <PieChart
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { color: grey[700] },
            },
          ]}
          height={size}
          margin={{ right: 0 }}
          slotProps={{
            legend: { hidden: true },
          }}
        />
      </MuiLink>
    );
  }
);
