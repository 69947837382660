import React from 'react';
import { theme } from 'theme';
import { FilterComponents } from 'components/FilterComponents';
import { DEFAULT_SEARCH_STUDY_KEY, STUDY_TYPES } from 'features/studies-table/constants';
import { messages } from '../messages';

export enum SearchGroups {
  SEARCH = 'SEARCH',
  CHECKBOXES = 'CHECKBOXES',
}

export const searchConfig = [
  {
    group: SearchGroups.SEARCH,
    accessorKey: DEFAULT_SEARCH_STUDY_KEY,
    FilterComponent: (props) => (
      <FilterComponents.SearchFilter
        {...props}
        label={messages.search}
        placeholder={messages.enterTheStudyInfo}
        background={theme.palette.background.paper}
        borderRadius="8px 0 0 8px"
      />
    ),
    defaultValue: '',
    width: 232,
  },
  {
    group: SearchGroups.SEARCH,
    accessorKey: 'studyTypeDisplay',
    FilterComponent: (props) => (
      <FilterComponents.AutocompleteFilter
        {...props}
        stringSeparator="-"
        labels={STUDY_TYPES}
        inputLabel={messages.studyType}
        background={theme.palette.background.paper}
      />
    ),
    defaultValue: [],
    width: 232,
    maxWidth: 271,
  },
  {
    group: SearchGroups.SEARCH,
    accessorKey: 'organs',
    FilterComponent: (props) => (
      <FilterComponents.AutocompleteFilter
        {...props}
        inputLabel={messages.experimentalModelsOrgan}
        background={theme.palette.background.paper}
      />
    ),
    defaultValue: [],
    width: 256,
    maxWidth: 304,
  },
  {
    group: SearchGroups.SEARCH,
    accessorKey: 'experimentalModels',
    FilterComponent: (props) => (
      <FilterComponents.AutocompleteFilter
        {...props}
        inputLabel={messages.experimentalModels}
        background={theme.palette.background.paper}
      />
    ),
    defaultValue: [],
    width: 224,
    maxWidth: 464,
  },
  {
    group: SearchGroups.CHECKBOXES,
    accessorKey: 'imageCount',
    FilterComponent: (props) => (
      <FilterComponents.CheckboxFilter
        {...props}
        classes={{ checkbox: 'pr-4' }}
        label={messages.images}
      />
    ),
    defaultValue: false,
  },
  {
    group: SearchGroups.CHECKBOXES,
    accessorKey: 'videoCount',
    FilterComponent: (props) => (
      <FilterComponents.CheckboxFilter
        {...props}
        classes={{ checkbox: 'pr-4' }}
        label={messages.videos}
      />
    ),
    defaultValue: false,
  },
  {
    group: SearchGroups.CHECKBOXES,
    accessorKey: 'omicDataSumCount',
    FilterComponent: (props) => (
      <FilterComponents.CheckboxFilter
        {...props}
        classes={{ checkbox: 'pr-4' }}
        label={messages.OMIC}
      />
    ),
    defaultValue: false,
  },
];
