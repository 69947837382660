import {
  ClickAwayListener as MuiClickAwayListener,
  Divider as MuiDivider,
  Link as MuiLink,
  MenuItem as MuiMenuItem,
  MenuList as MuiMenuList,
  Paper as MuiPaper,
  Popper as MuiPopper,
} from '@mui/material';
import { palette } from 'theme';
import { useState, KeyboardEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { KEYBOARD_KEYS } from 'shared';
import { ButtonStyled } from '../styled';

export interface DropdownGroup {
  title?: string;
  links: {
    title: string;
    url: string;
  }[];
}

interface DropdownProps {
  title: string;
  groups: DropdownGroup[];
}

export const Dropdown = ({ title, groups, ...props }: DropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'navigation-menu' : undefined;

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === KEYBOARD_KEYS.ESCAPE) {
      anchorEl?.focus();
      handleClose();
    }
  };

  return (
    <>
      <ButtonStyled
        id={id}
        isActive={open}
        withIcon
        onClick={handleClick}
        label={title}
        textTransform={false}
        disableTouchRipple
      />
      <MuiPopper
        {...props}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        onKeyDown={handleKeyDown}
        className="z-[999]"
      >
        <MuiClickAwayListener onClickAway={handleClose}>
          <MuiPaper>
            <MuiMenuList
              variant="menu"
              autoFocus
            >
              {groups.map((group, i) => [
                group.title && (
                  <div className="px-4 py-1.5 text-sm text-common-black font-semibold">
                    {group.title}
                  </div>
                ),
                ...group.links.map((link) => (
                  <MuiMenuItem
                    key={link.title}
                    className="transition"
                    sx={{ color: palette.text.primary, fontSize: '15px', padding: 0 }}
                  >
                    <MuiLink
                      component={RouterLink}
                      to={link.url}
                      color="inherit"
                      underline="none"
                      onClick={handleClose}
                      className="w-full px-4 py-1.5"
                    >
                      {link.title}
                    </MuiLink>
                  </MuiMenuItem>
                )),
                i !== groups.length - 1 && <MuiDivider />,
              ])}
            </MuiMenuList>
          </MuiPaper>
        </MuiClickAwayListener>
      </MuiPopper>
    </>
  );
};
