import { useProfile } from 'common/profile';
import { ReactNode } from 'react';

export type RoleGuardProps = {
  children: ReactNode;
  condition?: boolean;
};

export const RoleGuard = ({ condition, children }: RoleGuardProps) => {
  const { data: profile } = useProfile();

  // Need to check specifically false value cause condition can be undefined
  if (condition === false) {
    return null;
  }

  const shouldShow = condition || (!condition && profile?.role);

  if (!shouldShow) {
    return null;
  }

  return children;
};
