import { Dialog } from '@mui/material';
import { PaymentsPlans } from 'features/sign-up';
import { Button } from 'components/Button';
import { messages } from 'features/payments/messages';
import { useAuth } from 'common/auth';
import { PaymentsSubscriptionType } from 'features/payments/constants';

type PaymentsSelectPlanProps = {
  onSelectPaymentPlan: (type: PaymentsSubscriptionType) => void;
};
export const PaymentsSelectPlan = ({ onSelectPaymentPlan }: PaymentsSelectPlanProps) => {
  const { logout } = useAuth();
  return (
    <Dialog
      open
      PaperProps={{ className: 'max-w-[1308px] p-0 flex' }}
    >
      <div className="items-center justify-center flex-1 min-h-[898px] flex flex-col relative">
        <div className="absolute right-8 top-8">
          <Button
            variant="text"
            label={messages.plans.logout}
            type="button"
            onClick={() => logout()}
          />
        </div>
        <PaymentsPlans onClick={onSelectPaymentPlan} />
      </div>
    </Dialog>
  );
};
