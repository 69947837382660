import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import { ElementType } from '@react-spring/types';
import { UIMatch, useLocation, useMatches, Link as RouterLink } from 'react-router-dom';
import { AppRoutes, RouteHandle } from 'shared';

export const Breadcrumbs = () => {
  const matches = useMatches() as UIMatch<unknown, RouteHandle>[];
  const { pathname } = useLocation();

  const crumbLinks = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => {
      return { href: match.pathname, component: match.handle.crumb && match.handle.crumb() };
    });

  const isRootPath = pathname === AppRoutes.root();

  if (isRootPath) {
    return null;
  }

  return (
    <div className="page-container flex mt-6">
      <MuiBreadcrumbs separator="›">
        {crumbLinks.map(({ href, component }) =>
          pathname !== href ? (
            <Link
              component={RouterLink as ElementType}
              variant="body1"
              key={href}
              to={href}
              underline="hover"
            >
              {component}
            </Link>
          ) : (
            <Typography
              key={href}
              variant="body1"
              color="text.primary"
            >
              {component}
            </Typography>
          )
        )}
      </MuiBreadcrumbs>
    </div>
  );
};
