import {
  Controller,
  FieldError,
  FieldPath,
  FieldValues,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import {
  FormControlLabel,
  FormHelperText,
  Checkbox as MuiCheckbox,
  Typography,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabelProps,
  TypographyProps,
} from '@mui/material';
import { ReactNode } from 'react';

export type CheckboxProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> &
  Omit<MuiCheckboxProps, 'error'> & {
    error?: FieldError;
    label?: ReactNode;
    labelProps?: FormControlLabelProps;
    typographyProps?: TypographyProps;
  };

export const Checkbox = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  name,
  label,
  control: _control,
  error: _error,
  required,
  labelProps,
  typographyProps,
  ...props
}: CheckboxProps<TFieldValues, TName>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = errors[name] || _error;

  return (
    <Controller
      control={control || _control}
      name={name}
      render={({ field }) => (
        <>
          <FormControlLabel
            {...labelProps}
            required={required}
            control={
              <MuiCheckbox
                {...field}
                {...props}
                checked={field.value}
                id={name}
              />
            }
            label={
              label && (
                <Typography
                  variant="body2"
                  {...typographyProps}
                >
                  {label}
                </Typography>
              )
            }
          />

          {error && <FormHelperText error>{error.message as string}</FormHelperText>}
        </>
      )}
    />
  );
};
