import { BaseSyntheticEvent, useState } from 'react';
import { AuthLayout } from 'components/AuthLayout';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useAuthApiFetchCsrfMutation } from 'common/auth/auth-api';
import { useQuery } from 'hooks';
import { ContactFormRecipient } from 'shared';
import { SignUpComplete } from '../sign-up/components/SignUpComplete';
import { SignUpContactInfo } from './components/SignUpContactInfo';
import { messages } from './messages';
import { contactSalesSchema } from './schema';
import { SignUpPremSteps } from './constants';
import { ContactDto, useContactAdmin } from './contact-sales-api';

interface FormValues {
  organizationName: string;
  email: string;
  message: string;
}

export const ContactSales = () => {
  const [step, setStep] = useState(SignUpPremSteps.CONTACT_INFO);
  const [fetchCsrf] = useAuthApiFetchCsrfMutation();
  const [contactAdmin] = useContactAdmin();
  const { query } = useQuery();
  const recipientKey = 'recipient';

  const form = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(contactSalesSchema),
    defaultValues: {
      organizationName: '',
      email: '',
      message: '',
    },
  });

  const handleSubmit = async (values: FormValues, event?: BaseSyntheticEvent) => {
    event?.preventDefault();
    await fetchCsrf().unwrap();

    const recipient = query.get(recipientKey);
    const dto: ContactDto = {
      organization: values.organizationName,
      email: values.email,
      body: values.message,
      recipient: recipient ? ContactFormRecipient.SALES : ContactFormRecipient.SUPPORT,
    };

    contactAdmin(dto)
      .unwrap()
      .then(() => setStep(SignUpPremSteps.COMPLETE))
      .catch(() => {
        form.setError('organizationName', {});
        form.setError('email', {});
        form.setError('message', {
          message: messages.somethingWrong,
        });
      });
  };

  return (
    <AuthLayout>
      <FormProvider {...form}>
        {step === SignUpPremSteps.CONTACT_INFO && (
          <SignUpContactInfo
            onSubmit={form.handleSubmit(handleSubmit)}
            isValid={form.formState.isValid}
          />
        )}
        {step === SignUpPremSteps.COMPLETE && (
          <SignUpComplete
            title={messages.backYouSoon}
            description={messages.messageSent}
          />
        )}
      </FormProvider>
    </AuthLayout>
  );
};
