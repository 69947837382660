import { PageContents } from 'components/PageContents';
import { termsConditionsTemplate } from 'templates/termsConditions';

export const TermsConditions = () => {
  return (
    <div className="page-container py-8">
      <PageContents template={termsConditionsTemplate} />
    </div>
  );
};
