import { Button } from 'components/Button';
import { SignUpFormLayout } from 'features/sign-up/components/SignUpFormLayout';
import { messages } from 'features/sign-up/messages';
import {
  SIGN_UP_MAX_STEP,
  SIGN_UP_ORGANIZATION_TYPES,
  SignUpTrialStep,
} from 'features/sign-up/constants';
import { Select } from 'components/Select';
import { InputText } from 'components/InputText';

interface SignUpTrialStep1Props {
  onSubmit: () => void;
}

export const SignUpOrganizationInfo = ({ onSubmit }: SignUpTrialStep1Props) => (
  <SignUpFormLayout
    step={SignUpTrialStep.ORGANIZATION_INFO}
    maxStep={SIGN_UP_MAX_STEP}
    title={messages.step1.title}
    onSubmit={onSubmit}
    footer={
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        label={messages.step1.submit}
      />
    }
  >
    <div className="mb-10">
      <Select
        name="organizationType"
        label={messages.fields.organizationType}
        required
        size="small"
        fullWidth
        options={SIGN_UP_ORGANIZATION_TYPES}
      />
    </div>
    <div className="mb-10">
      <InputText
        name="institution"
        label={messages.fields.institution}
        placeholder={messages.fields.institutionPlaceholder}
        required
        size="small"
        fullWidth
      />
    </div>
    <div className="mb-10">
      <InputText
        name="organization"
        label={messages.fields.centerName}
        placeholder={messages.fields.centerNamePlaceholder}
        required
        size="small"
        fullWidth
      />
    </div>
  </SignUpFormLayout>
);
