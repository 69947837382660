import { useEffect, useState } from 'react';

export const useDebouncedInput = <TValue>(
  initialValue: TValue,
  debounceTime: number,
  callback: (value: TValue) => void
) => {
  const [value, setValue] = useState<TValue>(initialValue);

  const handleChangeValue = (currentValue: TValue) => setValue(currentValue);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      callback(value);
    }, debounceTime);

    return () => clearTimeout(timeoutId);
  }, [value, debounceTime]);

  return {
    setValue: handleChangeValue,
    value,
  };
};
