import React from 'react';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { ACTIONS } from './constants';

export interface Messages {
  a11yFirstPage: string;
  a11yPreviousPage: string;
  a11yNextPage: string;
  a11yLastPage: string;
  first: string;
  last: string;
}

interface ActionsProp extends TablePaginationActionsProps {
  messages: Messages;
}

export const getActionButtons = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  messages,
}: ActionsProp) => [
  {
    id: ACTIONS.FIRST,
    disabled: page === 0,
    onClick: (e) => onPageChange(e, 0),
    IconComponent: <span className="uppercase font-medium text-sm py-0.5">{messages.first}</span>,
    'aria-label': messages.a11yFirstPage,
  },
  {
    id: ACTIONS.PREV,
    disabled: page === 0,
    onClick: (e) => onPageChange(e, page - 1),
    IconComponent: <KeyboardArrowLeft />,
    'aria-label': messages.a11yPreviousPage,
  },
  {
    id: ACTIONS.NEXT,
    disabled: page >= Math.ceil(count / rowsPerPage) - 1,
    onClick: (e) => onPageChange(e, page + 1),
    IconComponent: <KeyboardArrowRight />,
    'aria-label': messages.a11yNextPage,
  },
  {
    id: ACTIONS.LAST,
    disabled: page >= Math.ceil(count / rowsPerPage) - 1,
    onClick: (e) => onPageChange(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1)),
    IconComponent: <span className="uppercase font-medium text-sm py-0.5">{messages.last}</span>,
    'aria-label': messages.a11yLastPage,
  },
];
