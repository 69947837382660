import React, { ReactNode } from 'react';
import { HeaderContext } from '@tanstack/react-table';
import { HeaderSorting } from './components/HeaderSorting';
import { messages } from './constants/messages';

interface TableHeaderCellProps<TColumn, TValue> {
  context: HeaderContext<TColumn, TValue>;
  value: ReactNode;
  a11yInvisibleLabel?: string;
  a11ySortedBy?: string;
}

export const TableHeaderCell = <TColumn, TValue>({
  context,
  value,
  a11yInvisibleLabel = messages.a11yActionsColumn,
  a11ySortedBy = messages.a11ySortBy,
}: TableHeaderCellProps<TColumn, TValue>) => {
  const { column } = context;
  const { isActions, isSortable } = column.columnDef.meta || {};

  return !isActions ? (
    <div className="flex justify-between items-center text-sm">
      {value}
      {isSortable && (
        <HeaderSorting
          onToggleSort={column.getToggleSortingHandler()}
          sorting={column.getIsSorted()}
          a11ySortedBy={a11ySortedBy}
        />
      )}
    </div>
  ) : (
    <div
      className="invisible"
      aria-label={a11yInvisibleLabel}
    />
  );
};
