import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'utils/axios-base-query';
import { Config } from 'shared';
import { getCookieByName } from 'utils/get-cookie-by-name';
import { CSRF_TOKEN_NAME } from 'common/auth/constants';
import { objectToFormData } from 'utils/object-to-form-data';
import { PaymentsSubscriptionType } from 'features/payments/constants';

export type PaymentPublicKey = { STRIPE_PUBLIC_KEY: string };
export interface PaymentSessionDto {
  quantity: number;
  subscription_type: PaymentsSubscriptionType;
  pay_entity?: string;
  no_cost: boolean;
  discount: boolean;
}

export interface PaymentSessionResponse {
  id: string;
}

export const paymentsApi = createApi({
  reducerPath: 'payments',
  baseQuery: axiosBaseQuery({ baseUrl: Config.api.baseUrlNoApi }),
  endpoints: (builder) => ({
    fetchPublicKey: builder.query<PaymentPublicKey, void>({
      query: () => ({
        url: `/stripe/get-stripe-public-key/`,
        skipInterceptors: true,
      }),
    }),
    createSession: builder.query<PaymentSessionResponse, PaymentSessionDto>({
      query: (body) => {
        const fd = objectToFormData(body);

        return {
          url: `/stripe/create-checkout-session/`,
          method: 'POST',
          body: fd,
          headers: {
            'X-CSRFTOKEN': getCookieByName(CSRF_TOKEN_NAME),
          },
        };
      },
    }),
  }),
});

export const { useFetchPublicKeyQuery, useCreateSessionQuery } = paymentsApi;
