import { Link } from '@mui/material';
import { purple, red, green, indigo, blue, orange, deepOrange, teal } from '@mui/material/colors';
import { AppRoutes } from 'shared';
import { TYPES } from '../types';
import { messages } from '../messages';

export const STUDY_CHART_VALUES = {
  0: { color: green[300], label: messages.excellent, id: 0 },
  1: { color: orange[300], label: messages.acceptable, id: 1 },
  2: { color: red[500], label: messages.poor, id: 2 },
};

export const STUDY_TYPES = {
  [TYPES.EFF]: {
    color: green[900],
    bgColor: green[50],
    fullName: messages.efficacy,
  },
  [TYPES.TOX]: {
    color: red[900],
    bgColor: red[50],
    fullName: messages.toxicity,
  },
  [TYPES.CC]: {
    color: indigo[900],
    bgColor: indigo[50],
    fullName: messages.chipCharacterization,
  },
  [TYPES.OMICS]: {
    color: blue[900],
    bgColor: blue[50],
    fullName: messages.omics,
  },
  [TYPES.VIVO]: {
    color: deepOrange[900],
    bgColor: orange[50],
    fullName: messages.inVivo,
  },
  [TYPES.DM]: {
    color: purple[900],
    bgColor: purple[50],
    fullName: messages.diseaseModel,
  },
  [TYPES.PK]: {
    color: teal[900],
    bgColor: teal[50],
    fullName: messages.PBPK,
  },
};

export const DEFAULT_SEARCH_STUDY_KEY = 'searchStudyKey';

export const ADD_STUDY_DROPDOWN_ITEMS = [
  {
    id: '1',
    component: Link,
    href: AppRoutes.addStudy(),
    item: messages.addStudy,
    sx: { pr: 5.5, minHeight: 36 },
  },
  {
    id: '2',
    component: Link,
    href: AppRoutes.addStudySet(),
    item: messages.addStudySet,
    sx: { pr: 5.5, minHeight: 36 },
  },
];
