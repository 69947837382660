import { useAuth } from 'common/auth';
import { useProfile } from 'common/profile';
import { useTruncateText } from 'hooks';
import { DropdownMenu } from 'components/DropdownMenu';
import { MenuItem } from '@mui/material';
import { palette } from 'theme';
import { USER_NAME_MENU_LABELS, UsernameMenuItems } from '../constants';

type MenuItemConfig = {
  label: string;
  href?: string;
  onClick?: () => void | Promise<void>;
};

export const UsernameMenu = () => {
  const { data: profile } = useProfile();
  const { logout } = useAuth();

  if (!profile?.username) {
    return null;
  }

  const { isTruncatedText, containerRef } = useTruncateText();

  const MENU_ITEMS_CONFIG: MenuItemConfig[] = [
    {
      label: USER_NAME_MENU_LABELS[UsernameMenuItems.LOG_OUT],
      onClick: logout,
    },
  ];

  const menuItems = MENU_ITEMS_CONFIG.map(({ label, href, onClick }) => (
    <MenuItem
      key={label}
      component={href ? 'a' : 'button'}
      href={href}
      className="transition min-w-[120px]"
      sx={{ color: palette.text.primary, fontSize: '15px', width: '100%' }}
      onClick={onClick}
    >
      {label}
    </MenuItem>
  ));

  return (
    <DropdownMenu
      title={profile.username}
      items={menuItems}
      isTruncatedText={isTruncatedText}
      containerRef={containerRef}
    />
  );
};
