import { RefObject, useEffect } from 'react';

export const useNonRemovable = (ref: RefObject<HTMLElement>, shouldDisconnect: boolean) => {
  useEffect(() => {
    const immortalModal = ref.current as HTMLElement;
    if (!immortalModal) {
      return;
    }

    const immortalModalParent = immortalModal.parentNode;
    const MOConfig = { attributes: true, childList: true, subtree: true };
    const callback = (mutationList: MutationRecord[], obs: MutationObserver) => {
      if (shouldDisconnect) {
        obs.disconnect();
        return;
      }

      mutationList.forEach((mutation) => {
        if (mutation.removedNodes.length && mutation.type === 'childList') {
          immortalModalParent?.appendChild(immortalModal as Node);
          obs.disconnect();
          obs.observe(immortalModalParent as ParentNode, MOConfig);
        }
      });
    };

    const observer = new MutationObserver(callback);
    observer.observe(immortalModalParent as ParentNode, MOConfig);

    // eslint-disable-next-line consistent-return
    return () => observer.disconnect();
  }, [shouldDisconnect]);
};
