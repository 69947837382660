import React from 'react';
import { Button as MuiButton, Typography as MuiTypography } from '@mui/material';
import { messages } from '../messages';

interface SearchableAutocompleteHeaderProps {
  name: string;
  count: number;
  showReset: boolean;
  onReset: () => void;
}

export const SearchableAutocompleteHeader = ({
  showReset,
  onReset,
  count,
  name,
}: SearchableAutocompleteHeaderProps) => (
  <div className="flex justify-between items-center mb-2 mt-3">
    <MuiTypography
      component="span"
      variant="body2"
      fontWeight="medium"
      color="text.primary"
      sx={{ my: 0.85 }}
    >
      {name}
      <span className="ml-1 text-grey-500 font-medium">({count})</span>
    </MuiTypography>
    {showReset && (
      <MuiButton
        variant="text"
        color="primary"
        size="small"
        onClick={onReset}
      >
        {messages.reset}
      </MuiButton>
    )}
  </div>
);
