import {
  MenuList,
  ClickAwayListener as MuiClickAwayListener,
  Paper as MuiPaper,
  Popper as MuiPopper,
  Tooltip as MUITooltip,
} from '@mui/material';
import { useState, KeyboardEvent, ReactNode, RefObject } from 'react';
import { KEYBOARD_KEYS } from 'shared';
import { ButtonStyled } from '../Navbar/styled';

interface DropdownMenuProps {
  title: string;
  items: ReactNode;
  isTruncatedText: boolean;
  containerRef: RefObject<HTMLSpanElement>;
}

export const DropdownMenu = ({
  title,
  items,
  isTruncatedText,
  containerRef,
}: DropdownMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'navigation-menu' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === KEYBOARD_KEYS.ESCAPE) {
      anchorEl?.focus();
      handleClose();
    }
  };

  return (
    <>
      <ButtonStyled
        id={id}
        isActive={open}
        withIcon
        onClick={handleClick}
        label={
          <span
            ref={containerRef}
            className="max-w-[92px] truncate"
          >
            {isTruncatedText ? (
              <MUITooltip title={title}>
                <span>{title}</span>
              </MUITooltip>
            ) : (
              <span>{title}</span>
            )}
          </span>
        }
        textTransform={false}
        disableTouchRipple
      />
      <MuiPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className="z-[999]"
      >
        <MuiClickAwayListener onClickAway={handleClose}>
          <MuiPaper>
            <MenuList
              autoFocusItem={open}
              id={`${id}-menu`}
              aria-labelledby={`${id}-button`}
              onKeyDown={handleKeyDown}
            >
              {items}
            </MenuList>
          </MuiPaper>
        </MuiClickAwayListener>
      </MuiPopper>
    </>
  );
};
