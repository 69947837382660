import { createBrowserRouter, Navigate } from 'react-router-dom';
import { SignUpRoutes } from 'routes/sign-up/SignUpRoutes';
import { PaymentsSuccessRedirect } from 'features/payments/PaymentsSuccessRedirect';
import { ContactSales } from 'features/sign-up';
import { AppRoutes } from '../shared';
import { HomeRoute } from './home-route';
import { RecoveryUsernameRoute } from './RecoveryUsername';
import { SignInRoute } from './SignIn';
import { StudyListRoute } from './StudyList';
import { TermsConditionsRoute } from './TermsConditions';
import { messages } from './messages';
import { PublicRoute } from './components/PublicRoute';
import { RedirectToLegacyApp } from './RedirectToLegacyApp';
import { PrivateRoute } from './components/PrivateRoute';

export const router = createBrowserRouter([
  {
    element: <PublicRoute />,
    children: [
      {
        path: AppRoutes.signIn(),
        element: <SignInRoute />,
      },

      {
        path: AppRoutes.recoveryUsername(),
        element: <RecoveryUsernameRoute />,
      },
    ],
  },
  {
    element: <PublicRoute />,
    children: [
      {
        path: AppRoutes.signUp(),
        children: SignUpRoutes,
      },
    ],
  },

  {
    id: AppRoutes.prem(),
    path: AppRoutes.prem(),
    element: <ContactSales />,
  },

  {
    id: AppRoutes.paymentCancel(),
    path: AppRoutes.paymentCancel(),
    element: <Navigate to={AppRoutes.root()} />,
  },

  {
    element: <PrivateRoute />,
    handle: {
      crumb: () => messages.breadcrumbs.home,
    },
    children: [
      {
        path: AppRoutes.root(),
        index: true,
        element: <HomeRoute />,
      },
      {
        path: AppRoutes.paymentSuccess(),
        element: <PaymentsSuccessRedirect />,
      },
      {
        path: AppRoutes.studyList(),
        element: <StudyListRoute />,
        handle: {
          crumb: () => messages.breadcrumbs.studies,
        },
      },
      {
        path: AppRoutes.about(),
        async lazy() {
          const { About } = await import('./About');
          return { Component: About };
        },
        handle: {
          crumb: () => messages.breadcrumbs.about,
        },
      },
      {
        path: AppRoutes.termsConditions(),
        element: <TermsConditionsRoute />,
        handle: {
          crumb: () => messages.breadcrumbs.termAndConditions,
        },
      },
    ],
  },
  {
    path: '*',
    element: <RedirectToLegacyApp />,
  },
]);
