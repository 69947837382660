import React from 'react';
import { Controller } from 'react-hook-form';
import { merge } from 'lodash';
import {
  Autocomplete as MuiAutocomplete,
  PopperPlacementType,
  PopperProps,
  SxProps,
  TextFieldProps,
  Theme,
} from '@mui/material';
import { AutocompleteCheckboxOption } from './components/AutocompleteCheckboxOption';
import { TextFieldStyled, ChipStyled } from './styles';
import { useAutocomplete } from './hooks';

interface AutocompleteProps {
  options: string[];
  name: string;
  placeholder: string;
  width: number;
  maxWidth: number;
  maxHeight?: number;
  background?: string;
  borderRadius?: string | number;
  placement?: PopperPlacementType;
  getLabel?: (option: string) => string;
  getChipLabel?: (selected: string[]) => string;
  inputProps?: TextFieldProps;
  sxProps?: SxProps<Theme> | undefined;
  popperProps?: Partial<PopperProps>;
}

export const Autocomplete = ({
  options,
  name,
  placeholder,
  inputProps,
  sxProps,
  popperProps,
  maxHeight = 370,
  getLabel = (option: string) => option,
  getChipLabel = (selected: string[]) => selected.join(),
}: AutocompleteProps) => {
  const { control, values, onChange, onReset } = useAutocomplete(name);

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={() => (
        <MuiAutocomplete
          multiple
          id={`select-${name}`}
          disableClearable
          disableCloseOnSelect
          getOptionLabel={getLabel}
          options={options}
          value={values}
          onChange={onChange}
          renderTags={(selected) => {
            const chipLabel = getChipLabel(selected);

            return (
              <ChipStyled
                key={chipLabel}
                label={chipLabel}
                size="small"
                color="primary"
                variant="filled"
                onDelete={onReset}
              />
            );
          }}
          renderOption={(props, option, { selected, inputValue }) => (
            <AutocompleteCheckboxOption
              key={option}
              optionProps={props}
              option={getLabel(option)}
              selected={selected}
              inputValue={inputValue}
            />
          )}
          renderInput={(props) => (
            <TextFieldStyled
              {...merge(props, inputProps)}
              label={placeholder}
            />
          )}
          componentsProps={{ popper: popperProps }}
          ListboxProps={{ style: { maxHeight } }}
          sx={sxProps}
        />
      )}
    />
  );
};
