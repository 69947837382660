import { FilterFnProps } from './types';

/*
 *  Filter Function for Multi Multi Select fields
 */
export const multiSelectFilterFunction = ({ filterValue, cellValue, options }: FilterFnProps) => {
  const { separator } = options || {};

  /*
   *  Not array | Empty filter
   */
  if (!Array.isArray(filterValue) || !filterValue.length) return true;

  /*
   *  Empty cell
   */
  if (!cellValue) return false;

  /*
   *  Split String into array
   *  Check each value
   */
  if (separator) {
    const values = cellValue.split(separator);

    return values.some((value) => filterValue.indexOf(value) !== -1);
  }

  /*
   *  Check cell value
   */
  return filterValue.indexOf(cellValue) !== -1;
};
