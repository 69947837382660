import styled from '@emotion/styled';
import { grey } from '@mui/material/colors';
import { Button } from 'components/Button';
import { palette, theme } from 'theme';

export const ButtonStyled = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'withIcon',
})<{
  isActive?: boolean;
  withIcon?: boolean;
}>`
  color: ${({ isActive }) => (isActive ? palette.common.black : palette.text.primary)};
  font-size: 1rem;
  font-weight: 600;
  transition-duration: ${theme.transitions.duration.short}ms;

  &:hover {
    color: ${palette.common.black};
    background-color: transparent;

    &:after {
      border-top-color: ${palette.common.black};
    }
  }

  &::after {
    content: ${({ withIcon }) => (withIcon ? "''" : '')};
    display: inline-block;
    border: 0.3rem solid transparent;
    border-top-color: ${({ isActive }) => (isActive ? palette.common.black : grey[400])};
    border-bottom: 0;
    margin-left: 0.5rem;
    vertical-align: middle;
    transition-duration: ${theme.transitions.duration.short}ms;
  }
`;
