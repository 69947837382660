import React, { useState } from 'react';
import { Link } from '@mui/material';
import { SearchStudies } from 'features/search-studies';
import { Button } from 'components/Button';
import { AppRoutes } from 'shared';
import { theme } from 'theme';
import { TabsStyled } from './styles/Tabs.styled';
import { HOME_SEARCH_TABS } from './constants';
import { messages } from './messages';

const DEFAULT_TAB = 0;

export const HomeSearch = () => {
  const [tab, setTab] = useState(DEFAULT_TAB);

  const handleChange = (id: number) => () => setTab(id);

  return (
    <>
      <TabsStyled className="w-full min-h-[141px]" />
      <div className="w-max mt-[-21px] mx-auto mb-10">
        {HOME_SEARCH_TABS.map(({ id, label }) => {
          const isActiveTab = tab === id;
          const isFirstTab = id === 0;
          const borderRadius = isFirstTab ? '8px 0 0 8px' : 'unset';

          return (
            <Button
              key={id}
              label={label}
              size="large"
              variant="contained"
              onClick={handleChange(id)}
              color={isActiveTab ? 'primary' : 'inherit'}
              sx={{
                borderRadius,
                ...(!isActiveTab && {
                  color: theme.palette.text.secondary,
                  backgroundColor: theme.palette.background.paper,
                }),
                px: 7.4,
              }}
            />
          );
        })}
        <Link href={AppRoutes.studyComponents()}>
          <Button
            label={messages.components}
            size="large"
            variant="contained"
            color="inherit"
            sx={{
              borderRadius: '0 8px 8px 0',
              color: theme.palette.text.secondary,
              backgroundColor: theme.palette.background.paper,
              px: 4.7,
            }}
          />
        </Link>
      </div>

      {tab === 0 && <SearchStudies />}
    </>
  );
};
