import React, { useState, FC, ReactNode, useRef, useEffect } from 'react';
import {
  MenuItem,
  MenuItemProps,
  ClickAwayListener,
  Popper,
  Grow,
  Paper,
  MenuList,
  PopperProps,
  GrowProps,
  PaperProps,
  MenuListProps,
} from '@mui/material';
import { KEYBOARD_KEYS } from 'shared';

interface DropdownListItem extends MenuItemProps {
  item: ReactNode;
  href?: string;
}

export type DropdownList = DropdownListItem[];

interface DropdownProps<TButtonProps> {
  id: string;
  ButtonComponent: FC<TButtonProps>;
  buttonComponentProps: TButtonProps;
  popperProps?: Omit<PopperProps, 'open' | 'anchorEl'>;
  growProps?: GrowProps;
  paperProps?: PaperProps;
  menuListProps?: MenuListProps;
  items?: DropdownList;
}

export const Dropdown = <TButtonProps,>({
  id,
  ButtonComponent,
  buttonComponentProps,
  popperProps,
  growProps,
  paperProps,
  menuListProps,
  items = [],
}: DropdownProps<TButtonProps>) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLElement>(null);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) return;

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === KEYBOARD_KEYS.ESCAPE || event.key === KEYBOARD_KEYS.TAB) {
      event.preventDefault();
      setOpen(false);
    }
  };

  // Return focus to the button after closing
  const prevOpen = useRef(open);

  useEffect(() => {
    const shouldBeFocused = anchorRef.current && prevOpen.current && !open;

    if (shouldBeFocused) anchorRef.current.focus();

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <ButtonComponent
        ref={anchorRef}
        {...buttonComponentProps}
        id={`${id}-button`}
        aria-controls={open ? `${id}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleToggle}
      />
      <Popper
        {...popperProps}
        open={open}
        className="z-[999]"
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...growProps}
            {...TransitionProps}
          >
            <Paper {...paperProps}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  {...menuListProps}
                  autoFocusItem={open}
                  id={`${id}-menu`}
                  aria-labelledby={`${id}-button`}
                  onKeyDown={handleListKeyDown}
                >
                  {items.map(({ id: itemId, item, ...props }) => (
                    <MenuItem
                      key={`${id}-item-${itemId}`}
                      {...props}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
