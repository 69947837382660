import React from 'react';
import { Badge as MuiBadge, Button as MuiButton } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import { messages } from '../messages';

interface TableFiltersButtonProps {
  count: number;
  onOpen: () => void;
}

export const TableFiltersButton = ({ count, onOpen }: TableFiltersButtonProps) => (
  <MuiButton
    variant="text"
    color="primary"
    onClick={onOpen}
    startIcon={
      <MuiBadge
        badgeContent={count}
        color="primary"
      >
        <FilterList />
      </MuiBadge>
    }
  >
    {messages.filters}
  </MuiButton>
);
