import { StorageService } from 'services/storage';

class CookieRepository {
  private readonly client = StorageService.getLocalStorageService();

  private readonly key = 'cookie.is_accepted';

  public getIsAccepted(): boolean {
    return this.client.get(this.key);
  }

  public setIsAccepted(value: boolean): void {
    this.client.set(this.key, value);
  }

  public clear(): void {
    this.client.remove(this.key);
  }
}

export const cookieRepository = new CookieRepository();
