export const DEFAULT_TABLE_OPTIONS = {
  cellHeight: 64,
  tableHeight: 575, // 8 lines + 1px borders
  tableMinWidth: 1200,
  withoutPagination: false,
  anchorActionsClassName: 'table-actions',
};

export const DEFAULT_STICKY_OPTIONS = {
  stickyHeader: true,
  stickyFirstColumn: true,
  stickyLastColumn: true,
};
