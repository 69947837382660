import { Divider, Link, Paper, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { messages } from 'components/PageContents/messages';
import { LiStyled } from 'components/PageContents/styled';

export interface Heading {
  title: string;
  id: string;
}

interface ContentNavigationProps {
  headingList: Heading[];
  activeHeadingId: string;
}

export const ContentNavigation = ({ headingList, activeHeadingId }: ContentNavigationProps) => {
  return (
    <Paper className="flex flex-col w-[304px]">
      <Typography
        variant="h5"
        className="py-2 px-6"
      >
        {messages.tableOfContent}
      </Typography>
      <Divider />
      <ul>
        {headingList.map((heading) => (
          <LiStyled
            key={heading.id}
            isActive={activeHeadingId === heading.id}
          >
            <Link
              underline="hover"
              color="inherit"
              href={`#${heading.id}`}
            >
              {heading.title}
            </Link>
          </LiStyled>
        ))}
      </ul>
      <Divider />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        className="flex justify-center gap-x-2 text-secondary-main my-2 py-2 px-6"
      >
        <ArrowUpwardIcon />
        {messages.backToTop}
      </a>
    </Paper>
  );
};
