import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';
import {
  AuthRegisterDto,
  AuthLoginResponse,
  useAuthApiFetchCsrfMutation,
  useAuthApiLoginMutation,
  useAuthApiLogoutMutation,
  useAuthRegisterMutation,
} from 'common/auth/auth-api';
import { getCookieByName } from 'utils/get-cookie-by-name';
import { CSRF_TOKEN_NAME } from 'common/auth/constants';
import { authRepository } from 'common/auth/auth-repository';
import { AppRoutes } from 'shared';

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextType {
  isAuthorized: boolean;
  signIn: (username: string, password: string) => Promise<AuthLoginResponse>;
  signUpState: {
    signUp: (dto: AuthRegisterDto) => Promise<boolean>;
    isLoading: boolean;
  };
  logout: () => Promise<void>;
}

const defaultIsHasSession = authRepository.isHasSession();

export const AuthContext = createContext<AuthContextType>({
  isAuthorized: false,
} as AuthContextType);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [fetchCsrf] = useAuthApiFetchCsrfMutation();
  const [login] = useAuthApiLoginMutation();
  const [logoutEndpoint] = useAuthApiLogoutMutation();
  const [register, { isLoading: isRegisterInProgress, isSuccess }] = useAuthRegisterMutation();

  const [isAuthorized, setIsAuthorized] = useState(defaultIsHasSession);

  const logout = useCallback(async () => {
    await fetchCsrf().unwrap();
    const csrfToken = getCookieByName(CSRF_TOKEN_NAME);
    await logoutEndpoint(csrfToken!).unwrap();
    authRepository.clearHasSession();
    setIsAuthorized(false);
    window.location.href = AppRoutes.signIn();
  }, []);

  const signIn = useCallback<AuthContextType['signIn']>(async (username, password) => {
    await fetchCsrf().unwrap();
    const csrfToken = getCookieByName(CSRF_TOKEN_NAME);

    const loginResponse = await login({
      username,
      password,
      csrfToken,
    }).unwrap();

    const { success, error } = loginResponse;

    if (success) {
      authRepository.setHasSession(true);
      setIsAuthorized(true);
    }

    if (error) {
      // TODO: remove when backend returns error's status code
      throw new Error('Login has been failed');
    }

    return loginResponse;
  }, []);

  const signUp = useCallback(
    async (dto: AuthRegisterDto) => {
      await fetchCsrf().unwrap();
      const csrfToken = getCookieByName(CSRF_TOKEN_NAME);

      const { success, error } = await register({ ...dto, csrfToken }).unwrap();

      if (error) {
        throw new Error(error);
      }

      return Boolean(success);
    },
    [fetchCsrf, register, isSuccess]
  );

  const context = useMemo(
    () => ({
      signIn,
      signUpState: {
        signUp,
        isLoading: isRegisterInProgress,
      },
      isAuthorized,
      logout,
    }),
    [signIn, signUp, isAuthorized, logout]
  );

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};
