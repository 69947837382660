import { ReactNode } from 'react';
import { Typography } from '@mui/material';

interface SignUpFormLayoutProps {
  title: string;
  onSubmit: () => void;
  step: number;
  maxStep: number;
  description?: string;
  children?: ReactNode;
  footer?: ReactNode;
}

export const SignUpFormLayout = ({
  title,
  description,
  step,
  maxStep,
  children,
  footer,
  onSubmit,
}: SignUpFormLayoutProps) => (
  <form
    onSubmit={onSubmit}
    className="h-full flex flex-col"
    noValidate
  >
    <div className="mt-11">
      <div>
        <Typography
          variant="subtitle1"
          color="text.primary"
        >
          Step {step}/{maxStep}
        </Typography>
        <div className="flex flex-col gap-4">
          <Typography variant="h4">{title}</Typography>
          <Typography
            variant="body1"
            component="p"
            color="text.secondary"
          >
            {description}
          </Typography>
        </div>
        <div className="mt-10">{children}</div>
      </div>
    </div>

    {footer && <div className="mt-auto mb-8">{footer}</div>}
  </form>
);
