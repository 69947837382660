import React, { useEffect, useState } from 'react';
import { Chip as MuiChip, IconButton as MuiIconButton } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import classNames from 'classnames';
import { useScroll } from 'hooks';
import { Label } from '../types';

interface SearchableAutocompleteChipsProps {
  values: string[];
  onDelete: (value: string) => () => void;
  labels?: Label[];
}

export const SearchableAutocompleteChips = ({
  values,
  labels,
  onDelete,
}: SearchableAutocompleteChipsProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const { isScrollable, containerRef } = useScroll([values]);

  // Collapse when values were changed
  useEffect(() => setExpanded(false), [values]);

  const handleClick = () => setExpanded((prev) => !prev);

  const showMore = isScrollable || expanded;

  return (
    <div className={classNames('relative mt-2 mb-3.5', { 'pr-10': showMore })}>
      <span
        ref={containerRef}
        className={!expanded ? 'line-clamp-2' : 'overflow-auto'}
      >
        {values?.map((value) => (
          <MuiChip
            key={value}
            label={value}
            size="small"
            component="span"
            className="leading-3"
            sx={{
              m: 0.25,
              backgroundColor: labels?.[value]?.bgColor,
              color: labels?.[value]?.color,
            }}
            onDelete={onDelete(value)}
          />
        ))}
      </span>
      {showMore && (
        <MuiIconButton
          className={classNames('right-0 top-0 ', { 'rotate-180': expanded })}
          size="small"
          sx={{
            position: 'absolute',
          }}
          onClick={handleClick}
        >
          <ExpandMore />
        </MuiIconButton>
      )}
    </div>
  );
};
