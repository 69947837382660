export const messages = {
  errors: {
    required: 'This field is required.',
    invalidPassword: 'Invalid password.',
    invalidPasswordConfirmation: 'Passwords don’t match. Please try again.',
    invalidEmail: 'Please enter a valid email address.',
  },

  fields: {
    organizationType: 'Organization type',
    institution: 'Institution',
    institutionPlaceholder: 'Institution Name',
    centerName: 'Group name',
    centerNamePlaceholder: 'Create a name for your group in Eve',

    username: 'Username',
    firstName: 'First name',
    lastName: 'Last name',
    password: 'Password',

    repeatPassword: 'Repeat password',
    repeatPasswordHelperText: 'Enter the same password as above',

    workEmail: 'Work email',
    workEmailPlaceholder: 'email@university.com',
    workEmailHelperText: 'Enter your work email',
  },

  prem: {
    contactForm: 'Contact form',
    writeMessage: 'Write a message',
    send: 'Send',
  },

  step1: {
    title: 'Organization info',
    submit: 'Next step',
  },
  step2: {
    title: 'Create account',
    submit: 'Create account',
    back: 'Back to previous',

    passwordValidation: {
      min: '8 characters minimum',
      lowercase: 'One lowercase character',
      upperscase: 'One uppercase character',
      special: 'One special character',
      number: 'One number',
    },
  },

  step3: {
    title: 'Proceed to payment',
    description: 'You will be redirected to the subscription payment pages, please, wait...',
  },

  emailVerification: {
    title: 'Congratulations',
    description:
      'Your account has been successfully activated. Log in to your account to start using our service',
    button: 'Go to Log in',
    error: 'Verification failed. ',
    loading: 'Verifying email...',
  },

  success: {
    title: 'Account created',
    description:
      'Your account has been successfully created.\nAn email with further instructions has been sent to your inbox',
  },
};
