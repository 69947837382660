import React, { ReactNode, RefObject } from 'react';
import classNames from 'classnames';

interface TruncatedTextCellProps {
  containerRef?: RefObject<HTMLElement>;
  className?: string;
  children: ReactNode;
}

export const TruncatedTextCell = ({
  containerRef,
  className,
  children,
}: TruncatedTextCellProps) => (
  <span
    ref={containerRef}
    className={classNames('line-clamp-2', className)}
  >
    {children}
  </span>
);
