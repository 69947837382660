import { useCallback, useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { messages } from './messages';
import { cookieRepository } from './cooke-repository';

const isAcceptedCookie = cookieRepository.getIsAccepted();

export const CookieBanner = () => {
  const [open, setOpen] = useState(!isAcceptedCookie);

  const closeBanner = useCallback(() => {
    cookieRepository.setIsAccepted(true);
    setOpen(false);
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Box
      className="z-50 fixed max-w-[360px] p-4 bottom-14 left-14 rounded-2xl bg-white"
      sx={{ boxShadow: 12 }}
    >
      <div className="flex items-center mb-1">
        <CookieOutlinedIcon color="primary" />
        <Typography
          variant="h6"
          className="basis-4/5"
          sx={{ margin: '0 8px' }}
        >
          {messages.weValue}
        </Typography>
        <IconButton onClick={closeBanner}>
          <ClearIcon />
        </IconButton>
      </div>
      <Typography
        variant="body1"
        color="text.secondary"
      >
        {messages.consentCookies}
      </Typography>
    </Box>
  );
};
