import { createApi } from '@reduxjs/toolkit/query/react';
import { CSRF_TOKEN_NAME } from 'common/auth/constants';
import { ContactFormRecipient } from 'shared';
import { axiosBaseQuery } from 'utils/axios-base-query';
import { getCookieByName } from 'utils/get-cookie-by-name';

export interface ContactDto {
  email: string;
  body: string;
  organization: string;
  recipient: ContactFormRecipient;
  subject?: string;
}

export interface ContactResponse {
  success?: string;
  error?: string;
}

export const contactSalesApi = createApi({
  reducerPath: 'contactSales',
  tagTypes: ['contactSales'],
  baseQuery: axiosBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    contact: builder.mutation<ContactResponse, ContactDto>({
      query: (body: ContactDto) => ({
        url: 'contact',
        method: 'POST',
        headers: {
          'X-CSRFTOKEN': getCookieByName(CSRF_TOKEN_NAME),
        },
        body,
      }),
    }),
  }),
});

export const { useContactMutation: useContactAdmin } = contactSalesApi;
