import React, { ChangeEvent } from 'react';
import {
  TextField as MuiTextField,
  BaseTextFieldProps as MuiBaseTextFieldProps,
  InputAdornment as MuiInputAdornment,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { useDebouncedInput } from 'hooks/use-debounced-input';

interface DebouncedSearchInputProps extends MuiBaseTextFieldProps {
  debounceTime?: number;
  value: string;
  onChange: (value: string) => void;
}

const DEFAULT_DEBOUNCED_TIME = 300;

export const TableSearch = ({
  value: initialValue,
  onChange: callback,
  debounceTime = DEFAULT_DEBOUNCED_TIME,
  ...props
}: DebouncedSearchInputProps) => {
  const { value, setValue } = useDebouncedInput(initialValue, debounceTime, callback);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

  return (
    <MuiTextField
      {...props}
      hiddenLabel
      type="search"
      variant="standard"
      size="medium"
      value={value}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <MuiInputAdornment position="start">
            <Search />
          </MuiInputAdornment>
        ),
      }}
    />
  );
};
