import styled from '@emotion/styled';

export const PaymentsPlansStyled = styled.div`
  background: linear-gradient(0, #e0d2f3 0%, #ebf7f7, #ffffff 100%);
  linear-gradient(0, #e0d2f3 0%, #ebf7f7, #ffffff 100%);
`;

export const PaymentsPlanButton = styled.button<{ active?: boolean }>`
  background: ${({ active, theme }) => (active ? theme.palette.primary.main : 'transparent')};
  min-height: 36px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: ${({ active, theme }) =>
    active ? theme.palette.common.white : theme.palette.text.secondary};
`;
