import { StorageService } from 'services/storage';

class AuthRepository {
  private readonly client = StorageService.getLocalStorageService();

  private readonly key = 'auth.has-session';

  isHasSession = () => {
    return Boolean(this.client.get(this.key));
  };

  clearHasSession = () => {
    return this.client.remove(this.key);
  };

  setHasSession = (value: boolean) => {
    return this.client.set(this.key, value);
  };
}

export const authRepository = new AuthRepository();
