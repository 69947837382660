export const messages = {
  backYouSoon: 'Thanks for your email',
  messageSent: 'Our team will contact you shortly',
  required: 'This field is required.',
  contactForm: 'Contact form',
  writeMessage: 'Write a message',
  send: 'Send',
  invalidEmail: 'Please enter a valid email address.',
  workEmailPlaceholder: 'email@university.com',
  organizationName: 'Your organization name',
  email: 'Your work email',
  message: 'Message',
  somethingWrong: 'Something went wrong. Please contact us support@eveanalytics.com',
};
