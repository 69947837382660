import { PaymentsPlans } from 'features/payments/components/PaymentsPlans/PaymentsPlans';
import { paymentsRepository } from 'features/payments/payments-repository';

export const PaymentsPlansSignUp = () => {
  return (
    <PaymentsPlans
      enableRoutingOnButtons
      onClick={(type) => paymentsRepository.setSelectedPaymentMethodType(type)}
    />
  );
};
