import React, { SyntheticEvent, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete as MuiAutocomplete } from '@mui/material';
import { AutocompleteCheckboxOption } from './components/AutocompleteCheckboxOption';
import { SearchableAutocompleteChips } from './components/SearchableAutocompleteChips';
import { SearchableAutocompleteHeader } from './components/SearchableAutocompleteHeader';
import { SearchableAutocompleteInput } from './components/SearchableAutocompleteInput';
import { useAutocomplete } from './hooks';
import { Label } from './types';

interface SearchableAutocompleteProps {
  options: string[];
  name: string;
  placeholder: string;
  labels?: Label[];
  maxHeight?: number;
  getLabel?: (option: string) => string;
}

const RESET_SEARCH_ACTION = 'reset';

export const SearchableAutocomplete = ({
  options,
  name,
  labels,
  placeholder,
  maxHeight = 370,
  getLabel = (option: string) => option,
}: SearchableAutocompleteProps) => {
  const { control, values, onChange, onReset, onDelete } = useAutocomplete(name);

  const [searchValue, setSearchValue] = useState<string>('');

  const handleSearch = (e: SyntheticEvent, search: string, reason: string) =>
    reason !== RESET_SEARCH_ACTION && setSearchValue(search);

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={() => (
        <div className="px-6 border-b border-gray-200">
          <SearchableAutocompleteHeader
            showReset={Boolean(values.length)}
            onReset={onReset}
            count={options.length}
            name={placeholder}
          />

          <MuiAutocomplete
            multiple
            id={`select-${name}`}
            size="small"
            disableClearable
            disableCloseOnSelect
            getOptionLabel={getLabel}
            options={options}
            value={values}
            onChange={onChange}
            inputValue={searchValue}
            onInputChange={handleSearch}
            renderTags={() => null} // Disable default behavior - options rendering
            renderOption={(props, option, { selected, inputValue }) => (
              <AutocompleteCheckboxOption
                key={option}
                optionProps={props}
                option={getLabel(option)}
                selected={selected}
                inputValue={inputValue}
              />
            )}
            renderInput={(params) => (
              <SearchableAutocompleteInput
                {...params}
                label={placeholder}
              />
            )}
            ListboxProps={{ style: { maxHeight } }}
          />

          <SearchableAutocompleteChips
            values={values}
            labels={labels}
            onDelete={onDelete}
          />
        </div>
      )}
    />
  );
};
