import React, { useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useQuery } from 'hooks';
import { getInitialFiltersState, FiltersConfig } from 'components/FilterUtils';
import { TableFiltersModal } from './components/TableFiltersModal';
import { getFilteredData, getFilterFns, getFiltersFromQuery, getFiltersKeys } from './utils';

interface TableFiltersProps<TColumn> {
  initialData: TColumn[];
  config: FiltersConfig;
  setData: (arg: TColumn[]) => void;
}

export const TableFilters = <TColumn,>({
  initialData,
  config,
  setData,
}: TableFiltersProps<TColumn>) => {
  // Search query params
  const { query, updateQuery, removeQuery } = useQuery();

  // Memoized data
  const [filterFns, initialFilters] = useMemo(
    () => [getFilterFns(config), getInitialFiltersState(config)],
    [config]
  );

  const form = useForm({ defaultValues: initialFilters });
  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = form;
  const filters = watch();
  const filteredData = useMemo(
    () => getFilteredData(initialData, filters, filterFns),
    [initialData, filters, filterFns]
  );

  const handleApplyFilters = (updatedFilters) => {
    updateQuery(updatedFilters);
    setData(filteredData);
  };

  const handleClearFilters = () => {
    reset(initialFilters);
    setData(initialData);

    removeQuery(getFiltersKeys(config));
  };

  useEffect(() => {
    const queryFilters = getFiltersFromQuery(query, config);

    reset(queryFilters, { keepDefaultValues: true });
    setData(getFilteredData(initialData, queryFilters, filterFns));
  }, [initialData]);

  return (
    <FormProvider {...form}>
      <TableFiltersModal
        onApply={handleSubmit(handleApplyFilters)}
        onReset={handleClearFilters}
        filteredDataCount={filteredData.length}
        config={config}
        disableResetAll={!isDirty}
      >
        {config.map(({ accessorKey, FilterComponent }) =>
          FilterComponent({
            key: accessorKey,
            name: accessorKey,
            data: initialData,
          })
        )}
      </TableFiltersModal>
    </FormProvider>
  );
};
