import styled from '@emotion/styled';
import { green, grey } from '@mui/material/colors';

export const PasswordValidationCheckmark = styled.div<{ active?: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: ${({ active }) => (active ? green[100] : grey[200])};
  color: ${({ active, theme }) => (active ? theme.palette.success.dark : grey[600])};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 14px;
  }
`;
