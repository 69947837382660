export const messages = {
  search: 'Search',
  enterTheStudyInfo: 'Search Study Name',
  studyType: 'Study Type',
  experimentalModelsOrgan: 'Organ Model',
  experimentalModels: 'Model Name',
  images: 'Images',
  videos: 'Videos',
  OMIC: 'OMIC',
};
