import React from 'react';
import { EditIcon, OpenIcon } from 'icons';
import { AppRoutes } from 'shared';
import { StudyTypes } from '../types';
import { messages } from '../messages';

export const getStudyActions = (id: number, permission: StudyTypes) => {
  const hasEditPermission = permission === StudyTypes.EDIT;

  return [
    {
      title: messages.open,
      Icon: <OpenIcon className="mr-3" />,
      key: StudyTypes.VIEW,
      link: AppRoutes.viewStudy(id),
    },
    ...(hasEditPermission
      ? [
          {
            title: messages.edit,
            Icon: <EditIcon className="mr-3" />,
            key: StudyTypes.EDIT,
            link: AppRoutes.editStudy(id),
          },
        ]
      : []),
  ];
};
