import React, { useMemo } from 'react';
import { createFullNameLabel, getSortedUniqValues, Label } from '../FilterUtils';
import { SearchableAutocomplete } from '../Autocomplete/SearchableAutocomplete';

interface SearchableAutocompleteFilterProps<TColumn> {
  data: TColumn[];
  name: string;
  inputLabel: string;
  stringSeparator?: string;
  labels?: Label[];
}

export const SearchableAutocompleteFilter = <TColumn,>({
  data,
  name,
  stringSeparator,
  labels,
  inputLabel,
}: SearchableAutocompleteFilterProps<TColumn>) => {
  const sortedUniqueOptions = useMemo(
    () => getSortedUniqValues(data, name, stringSeparator),
    [data, name, stringSeparator]
  );
  const getLabel = useMemo(() => createFullNameLabel(labels), [labels]);

  return (
    <SearchableAutocomplete
      options={sortedUniqueOptions}
      name={name}
      labels={labels}
      placeholder={inputLabel}
      getLabel={getLabel}
    />
  );
};
