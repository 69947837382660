import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { EmptyCell } from './components/EmptyCell';

export interface TableCellNumberProps extends TypographyProps {
  value: string | number;
}

export const TableCellNumber = ({ value, ...props }: TableCellNumberProps) => {
  const number = Number(value);

  if (!number) return <EmptyCell isNumber />;

  return (
    <Typography
      className="pr-3"
      {...props}
    >
      {number.toLocaleString('en')}
    </Typography>
  );
};
