import { FieldPath, FieldValues } from 'react-hook-form';
import { MenuItem } from '@mui/material';
import { InputProps } from 'components/types';
import { InputText } from 'components/InputText';

export interface SelectOption {
  value: string;
  label: string;
}

export const Select = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  options,
  ...props
}: InputProps<TFieldValues, TName> & {
  options: SelectOption[];
}) => {
  return (
    <InputText
      {...props}
      select
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
    </InputText>
  );
};
