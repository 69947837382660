import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  TableCellActions,
  TableCellChart,
  TableHeaderCell,
  TableCellLink,
  TableCellStudyTypes,
  TableCellText,
} from 'components/TableCells';
import { getStudyActions } from '../utils';
import { STUDY_CHART_VALUES, STUDY_TYPES } from '../constants';
import { messages } from '../messages';
import { StudyEntity } from '../types';

const columnHelper = createColumnHelper<StudyEntity>();

export const getTableConfig = () => [
  columnHelper.accessor('name', {
    cell: (context) => (
      <TableCellLink
        value={context.getValue()}
        link={context.row.original.overviewLink}
      />
    ),
    header: (context) => (
      <TableHeaderCell
        context={context}
        value={messages.studyName}
      />
    ),
    minSize: 280,
    meta: {
      alignCell: 'left',
      isSortable: true,
    },
  }),
  columnHelper.accessor('studyTypeDisplay', {
    cell: (context) => (
      <TableCellStudyTypes
        studyTypes={STUDY_TYPES}
        value={context.getValue()}
      />
    ),
    header: (context) => (
      <TableHeaderCell
        context={context}
        value={messages.studyTypes}
      />
    ),
    minSize: 176,
    meta: {
      alignCell: 'left',
      isSortable: true,
    },
  }),
  columnHelper.accessor('organs', {
    cell: (context) => <TableCellText value={context.getValue()} />,
    header: (context) => (
      <TableHeaderCell
        context={context}
        value={messages.experimentalModelsOrgan}
      />
    ),
    minSize: 160,
    meta: {
      alignCell: 'left',
      isSortable: true,
    },
  }),
  columnHelper.accessor('experimentalModels', {
    cell: (context) => <TableCellText value={context.getValue()} />,
    header: (context) => (
      <TableHeaderCell
        context={context}
        value={messages.experimentalModels}
      />
    ),
    minSize: 160,
    meta: {
      alignCell: 'left',
      isSortable: true,
    },
  }),
  columnHelper.accessor('reproNums', {
    cell: (context) => (
      <TableCellChart
        value={context.getValue()}
        chartValues={STUDY_CHART_VALUES}
        link={context.row.original.reproducibilityLink}
      />
    ),
    header: (context) => (
      <TableHeaderCell
        context={context}
        value={messages.reproducibilityStatus}
      />
    ),
    minSize: 156,
    meta: {
      alignCell: 'center',
      isSortable: true,
    },
  }),
  columnHelper.accessor('dataPointsCount', {
    cell: (context) => (
      <TableCellLink
        isNumber
        link={context.row.original.summaryLink}
        value={context.getValue()}
      />
    ),
    header: (context) => (
      <TableHeaderCell
        context={context}
        value={messages.dataPoints}
      />
    ),
    minSize: 136,
    meta: {
      alignCell: 'right',
      isSortable: true,
    },
  }),
  columnHelper.accessor('omicData2fcPointsCount', {
    cell: (context) => (
      <TableCellLink
        isNumber
        link={context.row.original.omicsLink}
        value={context.getValue()}
      />
    ),
    header: (context) => (
      <TableHeaderCell
        context={context}
        value={messages.omicDataPoints}
      />
    ),
    minSize: 128,
    meta: {
      alignCell: 'right',
      isSortable: true,
    },
  }),
  columnHelper.accessor('imageCount', {
    cell: (context) => (
      <TableCellLink
        isNumber
        link={context.row.original.imagesLink}
        value={context.getValue()}
      />
    ),
    header: (context) => (
      <TableHeaderCell
        context={context}
        value={messages.images}
      />
    ),
    minSize: 120,
    meta: {
      alignCell: 'right',
      isSortable: true,
    },
  }),
  columnHelper.accessor('videoCount', {
    cell: (context) => (
      <TableCellLink
        isNumber
        link={context.row.original.imagesLink}
        value={context.getValue()}
      />
    ),
    header: (context) => (
      <TableHeaderCell
        context={context}
        value={messages.videos}
      />
    ),
    minSize: 112,
    meta: {
      alignCell: 'right',
      isSortable: true,
    },
  }),
  columnHelper.accessor('startDate', {
    cell: (context) => <TableCellText value={context.getValue()} />,
    header: (context) => (
      <TableHeaderCell
        context={context}
        value={messages.startDate}
      />
    ),
    minSize: 120,
    meta: {
      alignCell: 'left',
      isSortable: true,
    },
  }),
  columnHelper.accessor('id', {
    cell: (context) => <TableCellText value={context.getValue()} />,
    header: (context) => (
      <TableHeaderCell
        context={context}
        value={messages.id}
      />
    ),
    minSize: 80,
    meta: {
      alignCell: 'left',
      isSortable: true,
    },
  }),
  columnHelper.display({
    id: 'actions',
    cell: (context) => {
      const { userPermission, id } = context.row.original;
      const actions = getStudyActions(id, userPermission);

      return (
        <TableCellActions
          actions={actions}
          context={context}
        />
      );
    },
    header: (context) => (
      <TableHeaderCell
        context={context}
        value={messages.actions}
      />
    ),
    meta: { isActions: true },
  }),
];
