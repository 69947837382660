import React from 'react';
import classNames from 'classnames';
import { Link as MUILink, LinkProps as MUILinkProps } from '@mui/material';
import { TableCellNumber } from './TableCellNumber';
import { TableCellText } from './TableCellText';
import { EmptyCell } from './components/EmptyCell';

interface TableCellLinkProps extends MUILinkProps {
  link: string;
  value: string | number;
  isNumber?: boolean;
}

export const TableCellLink = ({ value, link, isNumber, ...props }: TableCellLinkProps) => {
  if (!value) return <EmptyCell isNumber={isNumber} />;

  const Component = isNumber ? TableCellNumber : TableCellText;

  return (
    <MUILink
      className={classNames('flex w-full h-full items-center', {
        'justify-end': isNumber,
      })}
      underline="hover"
      {...props}
      href={link}
    >
      <Component value={value} />
    </MUILink>
  );
};
