import { useMemo, useState } from 'react';
import { ContentNavigation, Heading } from './components/ContentNavigation';
import { useScrollSpy } from './hook';

interface PageContentsProps {
  template: string;
}

export const PageContents = ({ template }: PageContentsProps) => {
  const [headingList, setHeadingList] = useState<Heading[]>([]);
  const [headingIds, setHeadingIds] = useState<string[]>([]);
  const headingRegex = /<h5>(.*?)<\/h5>/g;
  const offset = 65; // Height of the sticky header

  const modifiedTemplate = useMemo(
    () =>
      template.replace(headingRegex, (match, content) => {
        const heading: Heading = { title: content, id: `title-${content}` };
        const modifiedTag = `<h5 id="${heading.id}" class="scroll-mt-16">${content}</h5>`;

        setHeadingList((currentList) => [...currentList, heading]);
        setHeadingIds((currentIds) => [...currentIds, heading.id]);

        return modifiedTag;
      }),
    [template]
  );

  const [activeId] = useScrollSpy(headingIds, offset);

  return (
    <div className="flex justify-between">
      <div className="w-[calc(100%-24rem)]">
        <article
          className="prose prose-h4:text-[2rem] prose-h4:font-normal prose-h4:mb-2 prose-h5:text-2xl prose-h5:font-normal prose-h5:mt-6 prose-h5:mb-4 max-w-none"
          /* eslint-disable-next-line risxss/catch-potential-xss-react */
          dangerouslySetInnerHTML={{
            __html: modifiedTemplate,
          }}
        />
      </div>
      <div>
        <div className="sticky top-[57px]">
          <ContentNavigation
            headingList={headingList}
            activeHeadingId={activeId}
          />
        </div>
      </div>
    </div>
  );
};
