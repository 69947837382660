import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'utils/axios-base-query';
import { StudyEntity } from './types';

export const studyTableApi = createApi({
  reducerPath: 'studies',
  tagTypes: ['studies'],
  baseQuery: axiosBaseQuery({ baseUrl: '/special/list/' }),
  endpoints: (builder) => ({
    getStudies: builder.query<StudyEntity[], void>({
      query: () => ({
        url: `assaystudy/`,
      }),
    }),
  }),
});

export const { useGetStudiesQuery } = studyTableApi;
