export const messages = {
  plans: {
    getStarted: 'Get started',
    contactSales: 'Contact sales',
    monthly: 'Monthly',
    annual: 'Annual',
    annualSave: '(Save ~7.5%)',
    perSeat: 'per seat',
    contactSalesMessage: 'To purchase multiple seats or make a payment via Purchase Order, please',
    contactUs: 'Contact Us',
    chooseAPlan: 'Choose a plan tailored to your needs',
    logout: 'Log out',
  },

  success: {
    title: 'Successful payment',
    body: 'Payment was successful.\n Now you can start using EveAnalytics.',
    submit: 'Let’s start',
  },

  failed: {
    title: 'Failed payment',
    body:
      'There were some issues with the payment. \n' +
      'Please check your payment details and try again.',
    submit: 'Go to Payment details',
    close: 'Log out',
  },
};
