import { ReactNode } from 'react';
import { LogoEveAnalyticsIcon } from 'icons';
import { AuthLayoutStyled } from 'components/AuthLayout/AuthLayout.styled';
import classNames from 'classnames';

type AuthLayoutProps = {
  children?: ReactNode;
};

export const AuthLayout = ({ children }: AuthLayoutProps) => {
  return (
    <AuthLayoutStyled className="min-h-screen flex">
      <div className="flex-1" />
      <div className={classNames('flex-1 max-w-[41.6%] bg-white')}>
        <div className="flex flex-col w-[376px] h-full m-auto">
          <div className="pt-14 mx-auto">
            <LogoEveAnalyticsIcon
              width={138}
              height={20}
            />
          </div>
          {children}
        </div>
      </div>
    </AuthLayoutStyled>
  );
};
