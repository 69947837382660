import { Config } from 'shared/config';

export const AppRoutes = {
  root: () => '/',
  about: () => '/about',
  termsConditions: () => '/terms-conditions',
  privacyPolicy: () => '/privacy-policy',
  help: () => Config.helpPageUrl,
  feedback: () => '/feedback/submit',

  studyList: () => '/assays/assaystudy/',

  signIn: () => '/accounts/login/',
  signUp: () => '/register/',
  signUpTrial: () => '/register/trial',
  signUpLicense: () => '/register/license',
  signUpEmailVerification: () => '/register/verification-succeed',

  prem: () => '/prem',

  paymentSuccess: () => '/stripe/success',
  paymentCancel: () => '/stripe/cancel',

  recoveryUsername: () => '/recovery-username',

  search: () => '/search/',
  viewEditableStudies: () => '/assays/assaystudy/editable_studies/',
  viewStudySets: () => '/assays/assaystudyset/',
  studyComponents: () => '/studycomponents/',
  addStudy: () => '/assays/assaystudy/add/',
  viewStudy: (id: number) => `/assays/assaystudy/${id}/`,
  editStudy: (id: number) => `/assays/assaystudy/${id}/details`,
  addStudySet: () => '/assays/assaystudyset/add/',
  graphingReproducibility: () => '/assays/graphing_reproducibility/',
  heatmap: () => '/bioactivities/heatmap/#filter',
  cluster: () => '/bioactivities/cluster/#filter',
  pbpk: () => '/assays/pbpk_filter/',
  predictiveModeling: () => '/bioactivities/model/',
  webApi: () => '/api/',
  viewExperimentalModels: () => '/microdevices/model',
  viewMicrodevices: () => '/microdevices/device',
  viewMicrodeviceLocations: () => '/assays/location/',
  addExperimentalModel: () => '/microdevices/model/add/',
  addMicrodevice: () => '/microdevices/device/add/',
  viewChemicalData: () => '/compounds/',
  viewBioactivities: () => '/bioactivities/table/#filter',
  viewDrugTrials: () => '/drugtrials/',
  viewAdverseEvents: () => '/adverse_events/',
  compareAdverseEvents: () => '/compare_adverse_events/',
  compoundReport: () => '/compounds/report/#filter',
  addCompound: () => '/compounds/add/',
  addMultipleCompound: () => '/compounds/upload/',
  covidPortal: () => '/diseases/covid-19/',
  viewOtherDiseases: () => '/diseases/',
  viewCellTypes: () => '/cellsamples/celltype/',
  viewCellOrigins: () => '/cellsamples/cellsubtype/',
  viewCellSamples: () => '/cellsamples/cellsample/',
  addCellType: () => '/cellsamples/celltype/add/',
  addCellOrigin: () => '/cellsamples/cellsubtype/add/',
  addCellSample: () => '/cellsamples/cellsample/add/',

  changePassword: () => '/password_change/',
  groups: () => '/group/',
};
