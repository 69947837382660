export const messages = {
  studyName: 'Study Name',
  id: 'ID',
  studyTypes: 'Study Types',
  experimentalModels: 'Model Name',
  experimentalModelsOrgan: 'Organ Model',
  reproducibilityStatus: 'Reproducibility Status',
  dataPoints: 'Data Points',
  omicDataPoints: 'Omic Data Points',
  images: 'Images',
  videos: 'Videos',
  startDate: 'Start Date',
  actions: 'Actions',
  search: 'Search in Table',
  studyType: 'Study Type',
  experimentalModel: 'Experimental Model',
  OMICDataPoints: 'OMIC Data Points',
  showStudiesWith: 'Show studies with',
  excellent: 'Excellent',
  acceptable: 'Acceptable',
  poor: 'Poor',
  efficacy: 'Efficacy',
  toxicity: 'Toxicity',
  chipCharacterization: 'Chip Characterization',
  omics: 'Omics',
  inVivo: 'In Vivo',
  diseaseModel: 'Disease Model',
  PBPK: 'PBPK',
  open: 'Open',
  edit: 'Edit',
  add: 'Add',
  addStudy: 'Add Study',
  addStudySet: 'Add Study Set',
  studies: 'Studies',
};
