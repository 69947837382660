import React, { useMemo } from 'react';
import { createFullNameLabel, getSortedUniqValues, Label } from '../FilterUtils';
import { messages } from './messages';
import { Autocomplete } from '../Autocomplete/Autocomplete';

interface AutocompleteFilterProps<TColumn> {
  data: TColumn[];
  name: string;
  inputLabel: string;
  width: number;
  maxWidth: number;
  labels?: Label[];
  background?: string;
  borderRadius?: number;
  stringSeparator?: string;
}

const chipLabel = (selected: string[]) =>
  selected.length > 1 ? `${messages.selected} ${selected.length}` : selected[0];

export const AutocompleteFilter = <TColumn,>({
  data,
  name,
  stringSeparator,
  labels,
  inputLabel,
  width,
  maxWidth,
  background,
  borderRadius = 0,
}: AutocompleteFilterProps<TColumn>) => {
  const sortedUniqueOptions = useMemo(
    () => getSortedUniqValues(data, name, stringSeparator),
    [data, name, stringSeparator]
  );

  const getLabel = useMemo(() => createFullNameLabel(labels), [labels]);

  return (
    <Autocomplete
      options={sortedUniqueOptions}
      name={name}
      placeholder={inputLabel}
      width={width}
      maxWidth={maxWidth}
      background={background}
      getLabel={getLabel}
      getChipLabel={chipLabel}
      inputProps={{
        inputProps: { readOnly: true },
        InputProps: {
          className: 'min-w-0',
          sx: { borderRadius, ...(background ? { background } : {}) },
        },
      }}
      sxProps={{ marginLeft: '-1px', width }}
      popperProps={{
        style: { minWidth: width, maxWidth },
        placement: 'bottom-end',
      }}
    />
  );
};
