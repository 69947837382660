import { TextField as MUITextField } from '@mui/material';
import { InputProps } from 'components/types';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';
import { ReactNode } from 'react';

export const InputText = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  name,
  control: _control,
  fieldError,
  helperText,
  ...props
}: InputProps<TFieldValues, TName>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext() || { formState: {} };
  const error = fieldError || (errors && errors[name]);

  return (
    <Controller
      control={control || _control}
      name={name}
      render={({ field }) => (
        <MUITextField
          {...field}
          {...props}
          id={name}
          helperText={error ? (error.message as ReactNode) : helperText}
          error={!!error}
          autoComplete={name}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};
