import React from 'react';
import {
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  Typography as MuiTypography,
  TableBodyProps as MuiTableBodyProps,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

interface TableBodyEmptyProps extends MuiTableBodyProps {
  colSpan: number;
}

export const TableBodyEmpty = ({ colSpan, ...props }: TableBodyEmptyProps) => (
  <MuiTableBody {...props}>
    <MuiTableRow>
      <MuiTableCell colSpan={colSpan}>
        <span className="absolute top-28 w-full text-center">
          <InfoOutlined
            fontSize="large"
            color="action"
          />
          <MuiTypography
            className="pt-1.5"
            variant="subtitle2"
            color="text.secondary"
          >
            No data to display
          </MuiTypography>
        </span>
      </MuiTableCell>
    </MuiTableRow>
  </MuiTableBody>
);
