import axios from 'axios';
import * as humps from 'humps';
import { AppRoutes, Config, ErrorTypes } from 'shared';
import { authRepository } from 'common/auth/auth-repository';

export const applyAxiosSettings = () => {
  axios.defaults.baseURL = Config.api.baseUrl;
  axios.defaults.withCredentials = true;
};

export const applyAxiosInterceptors = () => {
  axios.interceptors.request.use(
    (request) => {
      if ('skipInterceptors' in request && request.skipInterceptors) {
        return request;
      }

      return {
        ...request,
        data: request.data instanceof FormData ? request.data : humps.decamelizeKeys(request.data),
        params: humps.decamelizeKeys(request.params),
      };
    },
    (err) => {
      throw err;
    }
  );

  axios.interceptors.response.use(
    (response) => {
      if ('skipInterceptors' in response.config && response.config.skipInterceptors) {
        return response;
      }

      return {
        ...response,
        data: humps.camelizeKeys(response.data),
      };
    },
    (err) => {
      throw err;
    }
  );
};

const clearSessionAndRedirectToLogin = () => {
  authRepository.clearHasSession();
  window.location.href = AppRoutes.signIn();
};

export const applyGlobalErrorHandling = () => {
  axios.interceptors.response.use(
    (response) => {
      /*
       * TODO: Workaround for catching 302 status of Redirections to login
       *  Should be removed after implementation: https://biosysjira.atlassian.net/browse/RX-188
       */
      const isUnauthorized =
        Config.shouldHandle302RedirectError &&
        response?.request?.responseURL?.includes('login/?next=');

      if (isUnauthorized) {
        clearSessionAndRedirectToLogin();

        return {
          ...response,
          data: undefined,
        };
      }

      return response;
    },
    (err) => {
      if (err.response.status === ErrorTypes.UNAUTHORIZED) {
        clearSessionAndRedirectToLogin();
      }

      return Promise.reject(err);
    }
  );
};
