import { configureStore } from '@reduxjs/toolkit';
import { authApi } from 'common/auth/auth-api';
import { paymentsApi } from 'features/payments/payments-api';
import { profileApi } from 'common/profile';
import { studyTableApi } from 'features/studies-table/study-table-api';
import { contactSalesApi } from 'features/contact-sales/contact-sales-api';

const middlewares = [
  authApi.middleware,
  studyTableApi.middleware,
  profileApi.middleware,
  paymentsApi.middleware,
  contactSalesApi.middleware,
];

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [studyTableApi.reducerPath]: studyTableApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [contactSalesApi.reducerPath]: contactSalesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: true,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
});
