import React, { useMemo } from 'react';
import { Chip, TooltipProps as MUITooltipProps, ChipProps as MUIChipProps } from '@mui/material';
import { EmptyCell } from './components/EmptyCell';
import { RenderTooltipCell } from './components/RenderTooltipCell';

interface StudyType {
  color: string;
  bgColor: string;
  fullName: string;
}

interface StudyTypes {
  [key: string]: StudyType;
}

interface TableCellStudyTypesProps {
  value: string;
  separator?: string;
  studyTypes: StudyTypes;
  tooltipProps?: MUITooltipProps;
  chipProps?: MUIChipProps;
}

export const TableCellStudyTypes = ({
  value,
  studyTypes,
  separator = '-',
  tooltipProps,
  chipProps,
}: TableCellStudyTypesProps) => {
  if (!value) return <EmptyCell />;

  const data = useMemo(() => value.split(separator), [value]);

  return (
    <>
      {data.map((type) => (
        <RenderTooltipCell
          placement="top"
          {...tooltipProps}
          key={type}
          title={studyTypes[type]?.fullName}
        >
          <Chip
            component="span"
            className="m-[3px] leading-3"
            sx={{
              backgroundColor: studyTypes[type]?.bgColor,
              color: studyTypes[type]?.color,
            }}
            {...chipProps}
            label={type}
          />
        </RenderTooltipCell>
      ))}
    </>
  );
};
