import { createApi } from '@reduxjs/toolkit/query/react';
import { SubscriptionPlans } from 'shared';
import { axiosBaseQuery } from '../../utils/axios-base-query';

export interface AuthLoginDto {
  username: string;
  password: string;
  csrfToken?: string;
}

export interface AuthRegisterDto {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  tariffPlan: SubscriptionPlans;
  organizationType: string;
  organization: string;
  institution: string;
  csrfToken?: string;
}

export interface AuthRegisterResponse {
  success?: string;
  error?: string;
  userId: number;
}

export interface AuthLoginResponse {
  error?: string;
  success: string;
  hasSubscription: boolean;
}

export interface AuthCsrfResponse {}

export const authApi = createApi({
  reducerPath: 'auth',
  tagTypes: ['auth'],
  baseQuery: axiosBaseQuery({ baseUrl: 'auth' }),
  endpoints: (builder) => ({
    fetchCsrf: builder.mutation<AuthCsrfResponse, void>({
      query: () => ({
        url: `/csrf`,
      }),
    }),

    login: builder.mutation<AuthLoginResponse, AuthLoginDto>({
      query: (body: AuthLoginDto) => ({
        url: `/login`,
        method: 'POST',
        body: { username: body.username, password: body.password },
        headers: {
          'X-CSRFTOKEN': body.csrfToken,
        },
      }),
    }),

    logout: builder.mutation({
      query: (csrfToken: string) => ({
        url: `/logout`,
        method: 'POST',
        headers: {
          'X-CSRFTOKEN': csrfToken,
        },
      }),
    }),

    register: builder.mutation<AuthRegisterResponse, AuthRegisterDto>({
      query: (body: AuthRegisterDto) => ({
        url: '/register',
        method: 'POST',
        headers: {
          'X-CSRFTOKEN': body.csrfToken,
        },
        body,
      }),
    }),
  }),
});

export const {
  useFetchCsrfMutation: useAuthApiFetchCsrfMutation,
  useLoginMutation: useAuthApiLoginMutation,
  useLogoutMutation: useAuthApiLogoutMutation,
  useRegisterMutation: useAuthRegisterMutation,
} = authApi;
