import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import inRange from 'lodash/inRange';

// Restrict value to be between the range [0, value]
const clamp = (value: number) => Math.max(0, value);

export const useScrollSpy = (ids: string[], offset: number = 0) => {
  const [activeId, setActiveId] = useState('');
  const idRef = useRef<string>();
  const hashRef = useRef<string>();

  idRef.current = activeId;

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;
      const hash = decodeURI(window.location.hash);

      if (hash && hash !== hashRef.current) {
        hashRef.current = hash;
        return;
      }

      const idsPositions = ids.map((id, index) => {
        const element = document.getElementById(id);

        if (!element) return { id, top: -1, bottom: -1 };

        const rect = element.getBoundingClientRect();
        const top = clamp(rect.top + scroll - offset);
        const bottom = clamp(rect.bottom + scroll - offset);

        return { id, index, top, bottom };
      });

      for (let i = 0; i < idsPositions.length; i++) {
        const { id, index, top, bottom } = idsPositions[i];
        const isBetween = inRange(scroll, top, bottom);
        if (isBetween) {
          setActiveId(id);
          return;
        }

        if (index && idRef.current && scroll < top && !isBetween) {
          setActiveId(ids[index - 1]);
          return;
        }
      }
    };

    listener();

    window.addEventListener('resize', listener);
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('resize', listener);
      window.removeEventListener('scroll', listener);
    };
  }, [ids, offset]);

  useLayoutEffect(() => {
    const listener = () => {
      const hash = decodeURI(window.location.hash);
      if (hash) {
        setActiveId(hash.slice(1));
      }
      hashRef.current = hash;
    };

    window.addEventListener('hashchange', listener);

    return () => {
      window.removeEventListener('hashchange', listener);
    };
  }, []);

  useEffect(() => {
    setActiveId(ids[0]);
  }, []);

  return [activeId];
};
