import { useSearchParams } from 'react-router-dom';
import { removeSearchParams, updateSearchParams } from '../utils/search-params';

export const useQuery = () => {
  const [query, setQuery] = useSearchParams();

  const updateQuery = <Params>(params: Params) => {
    setQuery(updateSearchParams(params, query));
  };

  const removeQuery = (paramsToRemove: string[]) => {
    setQuery(removeSearchParams(paramsToRemove, query));
  };

  return {
    query,
    updateQuery,
    removeQuery,
  };
};
