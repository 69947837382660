import React from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { ADD_STUDY_DROPDOWN_ITEMS } from '../constants';
import { messages } from '../messages';

export const StudyAddDropdown = () => (
  <Dropdown
    id="study-add-dropdown"
    ButtonComponent={Button}
    buttonComponentProps={{
      color: 'primary',
      size: 'large',
      variant: 'contained',
      label: messages.add,
      sx: { pl: 3.5, pr: 2.5, mt: 0.8, borderRadius: 2 },
      endIcon: <ExpandMore />,
    }}
    popperProps={{ placement: 'bottom-end' }}
    paperProps={{ sx: { borderRadius: 2 } }}
    menuListProps={{ sx: { mt: 0.5 } }}
    items={ADD_STUDY_DROPDOWN_ITEMS}
  />
);
