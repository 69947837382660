import { pick, isBoolean, isNumber, isString } from 'lodash';
import { parseSearchParams } from 'utils/search-params';
import { Filters, FiltersConfig, FiltersFns } from 'components/FilterUtils';

export const getAppliedFiltersCount = (filters: Filters) =>
  Object.values(filters).filter((value) => {
    if (Array.isArray(value)) return Boolean(value.length);

    if (isBoolean(value) || isNumber(value) || isString(value)) return Boolean(value);

    return false;
  }).length;

export const getFilteredData = <TColumn>(
  data: TColumn[],
  filters: Filters,
  filterFns: FiltersFns
) =>
  data.filter((item) => {
    const matchFilters = Object.keys(filters).map((filter) => {
      const filterValue = filters[filter];
      const cellValue = item[filter];
      const filterFn = filterFns[filter];

      return filterFn({ filterValue, cellValue });
    });

    return matchFilters.every(Boolean);
  });

export const getFilterFns = (config: FiltersConfig) =>
  config.reduce(
    (acc, { accessorKey, filterFn }) => ({
      ...acc,
      [accessorKey]: filterFn,
    }),
    {}
  );

export const getFiltersFromQuery = (searchParams: URLSearchParams, config: FiltersConfig) => {
  const query = parseSearchParams(searchParams);

  return pick(
    query,
    config.map(({ accessorKey }) => accessorKey)
  );
};

export const getFiltersKeys = (config: FiltersConfig) =>
  config.map(({ accessorKey }) => accessorKey);
