export const messages = {
  username: 'Username',
  password: 'Password',
  logIn: 'Log in',
  signUp: 'Sign up',
  forgotUsername: 'Forgot username?',
  enterValid: 'Please enter valid username or password.',
  fieldRequired: 'This field is required.',
  needAccount: 'Need an EveAnalytics account?',
  forgotPassword: 'Forgot password?',
};
