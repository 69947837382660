import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useCreateSessionQuery, useFetchPublicKeyQuery } from 'features/payments/payments-api';
import { useEffect, useState } from 'react';
import { messages } from 'features/sign-up/messages';
import { PaymentsSubscriptionType } from 'features/payments/constants';
import { Loading } from 'components/Loading';
import { Dialog, Typography } from '@mui/material';

interface PaymentsRedirectDialogProps {
  subscriptionType: PaymentsSubscriptionType;
}

export function PaymentsRedirectDialog({ subscriptionType }: PaymentsRedirectDialogProps) {
  const { data } = useFetchPublicKeyQuery();
  const { data: session } = useCreateSessionQuery({
    subscription_type: subscriptionType,
    no_cost: false,
    discount: false,
    pay_entity: 'test',
    quantity: 1,
  });
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    if (data) {
      loadStripe(data.STRIPE_PUBLIC_KEY).then(setStripe);
    }
  }, [data]);

  useEffect(() => {
    if (stripe && session) {
      stripe.redirectToCheckout({ sessionId: session.id });
    }
  }, [stripe, session]);

  return (
    <Dialog
      open
      PaperProps={{
        className:
          'flex flex-col max-w-[440px] items-center text-center pt-6 px-10 pb-8 gap-8 rounded-lg',
      }}
    >
      <div>
        <Typography
          color="text.primary"
          variant="h5"
          className="pb-4"
        >
          {messages.step3.title}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body1"
        >
          {messages.step3.description}
        </Typography>
      </div>
      <Loading size={32} />
    </Dialog>
  );
}
