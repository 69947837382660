import { FilterFnProps } from './types';

/*
 *  Filter Function for Exist/Not Exist fields
 */
export const checkboxFilterFunction = ({ filterValue, cellValue }: FilterFnProps) => {
  /*
   *  Check filter value:
   *    Filter by cell value - Exist | Not Exist
   *    Empty Filter - skip filtering
   */
  return filterValue ? Boolean(cellValue) : true;
};
