import React from 'react';
import { IconButton as MUIIconButton } from '@mui/material';
import { TablePaginationActionsProps as MUITablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { getActionButtons, Messages as ActionMessages } from './utils';

export type Messages = ActionMessages;

interface TablePaginationActionsProps extends MUITablePaginationActionsProps {
  messages: Messages;
}

export const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const buttons = getActionButtons(props);

  return (
    <div className="shrink-0 ml-5 mr-2">
      {buttons.map(({ IconComponent, id, ...buttonProps }) => (
        <MUIIconButton
          key={id}
          {...buttonProps}
        >
          {IconComponent}
        </MUIIconButton>
      ))}
    </div>
  );
};
