import { PaymentsPlanEntity } from 'features/payments/types';
import plan1Img from 'features/payments/img/plan-1.svg';
import plan3Img from 'features/payments/img/plan-3.svg';
import { AppRoutes, ContactFormRecipient } from 'shared';

export enum PaymentsSubscriptionType {
  MONTHLY = 'monthly',
  TRIAL = 'trial',
  ANNUAL = 'annual',
  PREM = 'prem',
}
export const PAYMENTS_PLAN: Array<PaymentsPlanEntity> = [
  {
    name: 'Free trial',
    priceCurrency: '$',
    benefits: [
      <>
        Full Access <span className="text-secondary">to EveAnalytics</span>
      </>,
      <>
        30-day <span className="text-secondary">risk</span> Free trial
      </>,
    ],
    route: AppRoutes.signUpTrial(),
    type: PaymentsSubscriptionType.TRIAL,
    img: plan1Img,
    buttonVariant: 'outlined',
    buttonText: 'Get Started',
  },
  {
    name: 'On-line version',
    price: 225,
    priceAnnual: 2500,
    priceCurrency: '$',
    benefits: [
      <>
        <span className="text-secondary">Browse Eve’s Database of</span> Models, Assays, Studies{' '}
        <span className="text-secondary">and more</span>
      </>,
      <>
        <span className="text-secondary">Use Eve’s</span> Analytics Tools{' '}
        <span className="text-secondary">
          (Reproducibility, Sample size determination, PBPK modeling)
        </span>
      </>,
      <>
        Manage, Analyze, Report <span className="text-secondary">your Studies and Data in Eve</span>
      </>,
    ],
    route: AppRoutes.signUpLicense(),
    type: PaymentsSubscriptionType.MONTHLY,
    buttonVariant: 'contained',
    buttonText: 'Subscribe',
  },
  {
    name: 'On-premises version',
    benefits: [
      <>
        <span className="text-secondary">EveAnalytics</span> managed by you behind your firewall
      </>,
      <>
        <span className="text-secondary">Available with</span> Site License
      </>,
    ],
    route: `${AppRoutes.prem()}?recipient=${ContactFormRecipient.SALES}`,
    type: PaymentsSubscriptionType.PREM,
    img: plan3Img,
    buttonVariant: 'outlined',
    buttonText: 'Contact sales',
  },
];

export enum PaymentsStep {
  TERMS_AND_CONDITIONS,
  SELECT_SUBSCRIPTION,
  LOAD_SUBSCRIPTION,
  NONE,
  SUCCESS,
  FAILED,
}

export enum PaymentQueryKeys {
  SHOW_SUCCESS = 'showSuccess',
  SHOW_FAILED = 'showFailed',
}
