import { useAcceptTerms, useProfile } from 'common/profile';
import { PaymentsSelectPlan } from 'features/payments/PaymentsSelectPlan';
import {
  PaymentQueryKeys,
  PaymentsStep,
  PaymentsSubscriptionType,
} from 'features/payments/constants';
import { useEffect, useState } from 'react';
import { PaymentsRedirectDialog } from 'features/payments/PaymentsRedirectDialog';
import { TermsConditionsModal } from 'components/TermsConditionsModal';
import { useAuth } from 'common/auth';
import { ProfileEntity } from 'common/profile/types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PaymentSuccess } from 'features/payments/components/PaymentsSuccess';
import { PaymentFailed } from 'features/payments/components/PaymentsFailed';
import { AppRoutes, ContactFormRecipient } from 'shared';
import { paymentsRepository } from './payments-repository';

const getStepByProfileState = (profile: ProfileEntity) => {
  const hasTermsAndConditions = profile?.hasAcceptedTermsAndConditions;
  const hasSubscription = profile?.hasSubscription;

  if (!hasTermsAndConditions) {
    return PaymentsStep.TERMS_AND_CONDITIONS;
  }

  if (!hasSubscription) {
    return PaymentsStep.SELECT_SUBSCRIPTION;
  }

  return PaymentsStep.NONE;
};

export const Payments = () => {
  const subscription = paymentsRepository.getSelectedPaymentMethodType();

  const { data: profile, isSuccess } = useProfile();
  const navigate = useNavigate();
  const [acceptTerms] = useAcceptTerms();
  const { logout } = useAuth();
  const [searchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [step, setStep] = useState<PaymentsStep>(PaymentsStep.NONE);
  const [selectedSubscription, setSelectedSubscription] = useState<PaymentsSubscriptionType | null>(
    subscription
  );

  const showSuccess = searchParams.get(PaymentQueryKeys.SHOW_SUCCESS);
  const showFailed = searchParams.get(PaymentQueryKeys.SHOW_FAILED);
  const handleFinish = () => {
    setStep(PaymentsStep.NONE);
    navigate(AppRoutes.root());
  };

  useEffect(() => {
    if (isSuccess && !isLoaded) {
      const nextStep = getStepByProfileState(profile);
      if (nextStep !== PaymentsStep.NONE) {
        setStep(getStepByProfileState(profile));
      }
      setIsLoaded(true);
    }
  }, [isSuccess, isLoaded]);

  useEffect(() => {
    if (showSuccess) {
      setStep(PaymentsStep.SUCCESS);
    }

    if (showFailed) {
      setStep(PaymentsStep.FAILED);
    }
  }, [showSuccess, showFailed]);

  const handleAcceptTerms = () => {
    acceptTerms()
      .unwrap()
      .then(() => {
        if (selectedSubscription) {
          setStep(PaymentsStep.LOAD_SUBSCRIPTION);
        } else {
          setStep(PaymentsStep.SELECT_SUBSCRIPTION);
        }
      });
  };

  const handleSelectPaymentMethod = (type: PaymentsSubscriptionType) => {
    if (type === PaymentsSubscriptionType.PREM) {
      window.open(`${AppRoutes.prem()}?recipient=${ContactFormRecipient.SALES}`, '_blank');
      return;
    }

    setSelectedSubscription(type);
    setStep(PaymentsStep.LOAD_SUBSCRIPTION);
  };

  if (step === PaymentsStep.NONE) {
    return null;
  }

  return (
    <>
      {step === PaymentsStep.TERMS_AND_CONDITIONS && (
        <TermsConditionsModal
          onClose={logout}
          onAccept={handleAcceptTerms}
        />
      )}

      {step === PaymentsStep.SELECT_SUBSCRIPTION && (
        <PaymentsSelectPlan onSelectPaymentPlan={handleSelectPaymentMethod} />
      )}

      {step === PaymentsStep.LOAD_SUBSCRIPTION && selectedSubscription && (
        <PaymentsRedirectDialog subscriptionType={selectedSubscription} />
      )}

      {step === PaymentsStep.SUCCESS && <PaymentSuccess onSubmit={handleFinish} />}

      {step === PaymentsStep.FAILED && (
        <PaymentFailed
          onClose={logout}
          onSubmit={() => setStep(PaymentsStep.SELECT_SUBSCRIPTION)}
        />
      )}
    </>
  );
};
