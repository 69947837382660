import { useEffect, useRef, useState } from 'react';

export const useScroll = <TDependency>(dependencies: TDependency[] = []) => {
  const containerRef = useRef<HTMLElement>(null);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);

  useEffect(() => {
    const current = containerRef?.current;
    if (!current) return undefined;

    const listener = () => setIsScrollable(current?.scrollHeight > current?.clientHeight);

    const observer = new ResizeObserver(listener);
    observer.observe(current);

    return () => observer.unobserve(current);
  }, [...dependencies, containerRef, isScrollable]);

  return { containerRef, isScrollable };
};
