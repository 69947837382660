import { StorageService } from 'services/storage';
import { PaymentsSubscriptionType } from 'features/payments/constants';

class PaymentsRepository {
  private readonly client = StorageService.getLocalStorageService();

  private readonly key = 'payment.type';

  public getSelectedPaymentMethodType(): PaymentsSubscriptionType {
    return this.client.get(this.key) || null;
  }

  public setSelectedPaymentMethodType(value: PaymentsSubscriptionType): void {
    this.client.set(this.key, value);
  }

  public clear(): void {
    this.client.remove(this.key);
  }
}

export const paymentsRepository = new PaymentsRepository();
