import {
  ColumnDef,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';

const DEFAULT_TABLE_CONFIG = {
  initialState: {
    pagination: {
      pageIndex: 0,
      pageSize: 20,
    },
  },
};

interface UseTableProps<TColumn, TValue> {
  data: TColumn[];
  columns: ColumnDef<TColumn, TValue>[];
  tableOptions?: Partial<TableOptions<TColumn>>;
}

export const useTable = <TColumn, TValue>({
  data,
  columns,
  tableOptions,
}: UseTableProps<TColumn, TValue>) =>
  useReactTable({
    ...DEFAULT_TABLE_CONFIG,
    ...tableOptions,
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
