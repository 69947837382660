import { SignUp } from 'features/sign-up';
import { AppRoutes } from 'shared';
import { PaymentsPlansSignUp } from 'features/payments/components/PaymentsPlans/PaymentsPlansSignUp';
import { SignUpEmailVerificationComplete } from 'features/sign-up/components/SignUpEmailVerificationComplete';

export const SignUpRoutes = [
  {
    id: AppRoutes.signUpTrial(),
    path: AppRoutes.signUpTrial(),
    element: <SignUp trial />,
  },

  {
    id: AppRoutes.signUpLicense(),
    path: AppRoutes.signUpLicense(),
    element: <SignUp />,
  },

  {
    id: AppRoutes.signUpEmailVerification(),
    path: AppRoutes.signUpEmailVerification(),
    element: <SignUpEmailVerificationComplete />,
  },

  {
    index: true,
    element: <PaymentsPlansSignUp />,
  },
];
