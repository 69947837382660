import { Divider as MuiDivider, Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { LogoNumaIcon, MailIcon } from 'icons';
import { AppRoutes, Config } from 'shared';
import { palette } from 'theme';
import { FOOTER_LINKS } from './constants';
import { messages } from './messages';

export const Footer = () => (
  <div className="py-6 border-t border-grey-250 tracking-[.15px]">
    <div className="page-container">
      <div className="flex justify-between items-center mb-5 text-primary leading-7">
        <div className="flex">
          <RouterLink
            className="mr-4"
            to={AppRoutes.root()}
          >
            <LogoNumaIcon
              width={94}
              height={24}
            />
          </RouterLink>
          <span>{messages.complexBiology}</span>
        </div>
        <MuiLink
          className="flex items-center"
          color="inherit"
          underline="hover"
          href={`mailto:${Config.infoEmail}`}
        >
          <MailIcon
            width={18}
            color={palette.action.active}
          />
          <span className="ml-2">{Config.infoEmail}</span>
        </MuiLink>
      </div>
      <MuiDivider />
      <div className="flex justify-between items-center mt-5 text-secondary">
        <div>{messages.copyright}</div>
        <ul className="flex gap-x-4">
          {FOOTER_LINKS.map(({ title, url, shouldOpenNewTab }) => (
            <li key={title}>
              <MuiLink
                component={RouterLink}
                to={url}
                color="inherit"
                underline="hover"
                {...(shouldOpenNewTab ? { target: '_blank' } : {})}
              >
                {title}
              </MuiLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);
