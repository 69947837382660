import React, { useCallback } from 'react';
import { PaginationState, Updater } from '@tanstack/react-table';
import {
  TablePagination as MuiTablePagination,
  TablePaginationBaseProps as MuiTablePaginationProps,
} from '@mui/material';
import { palette } from 'theme';

import { PAGE_OPTIONS } from './constants';
import { TablePaginationActions, Messages as ActionsMessages } from './TablePaginationActions';

export interface Messages extends ActionsMessages {
  a11yRowsPerPage: string;
}

export interface TablePaginationProps extends MuiTablePaginationProps {
  length: number;
  state: PaginationState;
  onPageIndex: (updater: Updater<number>) => void;
  onPageSize: (updater: Updater<number>) => void;
  messages: Messages;
}

export const TablePagination = ({
  length,
  onPageIndex,
  onPageSize,
  state: { pageSize, pageIndex },
  messages,
  ...props
}: TablePaginationProps) => {
  const ActionsComponent = useCallback(
    (actionProps) => (
      <TablePaginationActions
        {...actionProps}
        messages={messages}
      />
    ),
    [messages]
  );

  return (
    <MuiTablePagination
      component="div"
      SelectProps={{
        inputProps: { 'aria-label': messages.a11yRowsPerPage, id: messages.a11yRowsPerPage },
      }}
      sx={{ borderTop: `1px solid ${palette.divider}` }}
      {...props}
      rowsPerPageOptions={PAGE_OPTIONS}
      count={length}
      rowsPerPage={pageSize}
      page={pageIndex}
      onPageChange={(_, page) => onPageIndex(page)}
      onRowsPerPageChange={(e) => onPageSize(Number(e.target.value))}
      ActionsComponent={ActionsComponent}
    />
  );
};
