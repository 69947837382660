import { createTheme } from '@mui/material/styles';
import { palette } from './palette';

export const theme = createTheme({
  palette,
  typography: {
    h1: {
      fontFamily: 'Merriweather, Roboto, -apple-system, BlinkMacSystemFont, ui-sans-serif',
      fontSize: '6rem',
      fontWeight: '300',
      lineHeight: 1.167,
      color: palette.text.primary,
    },
    h2: {
      fontFamily: 'Merriweather, Roboto, -apple-system, BlinkMacSystemFont, ui-sans-serif',
      fontSize: '4.5rem',
      fontWeight: '400',
      lineHeight: 1,
      color: palette.text.primary,
    },
    h3: {
      fontFamily: 'Merriweather, Roboto, -apple-system, BlinkMacSystemFont, ui-sans-serif',
      fontSize: '3rem',
      fontWeight: '300',
      lineHeight: 1.167,
      color: palette.text.primary,
    },
    h4: {
      fontFamily: 'Merriweather, Roboto, -apple-system, BlinkMacSystemFont, ui-sans-serif',
      fontSize: '2rem',
      fontWeight: '400',
      lineHeight: 1.313,
      color: palette.text.primary,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: '400',
      lineHeight: 1.333,
      color: palette.text.primary,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: '500',
      lineHeight: 1.6,
      color: palette.text.primary,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: '500',
      lineHeight: 1.571,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: 1.249,
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: 1.167,
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: 2.667,
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: palette.error.main,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: {
          color: palette.error.main,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '0 12px',
          fontSize: 16,
          wordBreak: 'break-word',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            borderRadius: 8,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 'auto',
        },
        label: {
          display: 'block',
          whiteSpace: 'normal',
          padding: '6px 10px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.custom.link.primary,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
  },
});
