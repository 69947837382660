import React from 'react';
import { FilterUtils } from 'components/FilterUtils';
import { FilterComponents } from 'components/FilterComponents';
import { STUDY_TYPES } from '../constants';
import { messages } from '../messages';

export const filtersConfig = [
  {
    accessorKey: 'studyTypeDisplay',
    filterFn: (props) =>
      FilterUtils.multiSelect({
        ...props,
        options: { ...props.options, separator: '-' },
      }),
    FilterComponent: (props) => (
      <FilterComponents.SearchableAutocompleteFilter
        {...props}
        stringSeparator="-"
        labels={STUDY_TYPES}
        inputLabel={messages.studyType}
      />
    ),
    defaultValue: [],
  },
  {
    accessorKey: 'organs',
    filterFn: FilterUtils.multiSelect,
    FilterComponent: (props) => (
      <FilterComponents.SearchableAutocompleteFilter
        {...props}
        inputLabel={messages.experimentalModelsOrgan}
      />
    ),
    defaultValue: [],
  },
  {
    accessorKey: 'experimentalModels',
    filterFn: FilterUtils.multiSelect,
    FilterComponent: (props) => (
      <FilterComponents.SearchableAutocompleteFilter
        {...props}
        inputLabel={messages.experimentalModels}
      />
    ),
    defaultValue: [],
  },
  {
    accessorKey: 'imageCount',
    filterFn: FilterUtils.checkbox,
    FilterComponent: (props) => (
      <FilterComponents.CheckboxFilter
        {...props}
        classes={{ checkbox: 'w-full px-6 py-0.5 mr-0' }}
        label={messages.images}
        title={messages.showStudiesWith}
      />
    ),
    defaultValue: false,
  },
  {
    accessorKey: 'videoCount',
    filterFn: FilterUtils.checkbox,
    FilterComponent: (props) => (
      <FilterComponents.CheckboxFilter
        {...props}
        classes={{ checkbox: 'w-full px-6 py-0.5 mr-0' }}
        label={messages.videos}
      />
    ),
    defaultValue: false,
  },
  {
    accessorKey: 'dataPointsCount',
    filterFn: FilterUtils.checkbox,
    FilterComponent: (props) => (
      <FilterComponents.CheckboxFilter
        {...props}
        classes={{ checkbox: 'w-full px-6 py-0.5 mr-0' }}
        label={messages.dataPoints}
      />
    ),
    defaultValue: false,
  },
  {
    accessorKey: 'omicDataSumCount',
    filterFn: FilterUtils.checkbox,
    FilterComponent: (props) => (
      <FilterComponents.CheckboxFilter
        {...props}
        classes={{ checkbox: 'w-full px-6 py-0.5 mr-0' }}
        label={messages.OMICDataPoints}
      />
    ),
    defaultValue: false,
  },
];
