import { useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as MUILink } from '@mui/material';
import { Button } from 'components/Button';
import { AppRoutes, Config } from 'shared';
import { InputText } from 'components/InputText';
import { InputPassword } from 'components/InputPassword';
import { useAuth } from 'common/auth';
import { AuthLayout } from 'components/AuthLayout';
import styled from '@emotion/styled';
import { palette } from 'theme';
import { SignInHeader } from './components/SignInHeader';
import { schema } from './schema';
import { messages } from './messages';

interface FormValues {
  username: string;
  password: string;
}

export const SignInDivider = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${palette.action.active};
  margin: 0 16px;
`;

export const SignIn = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const { signIn } = useAuth();
  const onSubmitHandler = ({ username, password }: FormValues, event) => {
    event.preventDefault();
    signIn(username, password)
      .then(() => {
        navigate(AppRoutes.root());
      })
      .catch(() => {
        setError('username', {});
        setError('password', {
          message: messages.enterValid,
        });
      });
  };

  return (
    <AuthLayout>
      <div className="mt-[12.75rem]">
        <SignInHeader />
        <form
          className="flex flex-col mt-10"
          noValidate
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <div className="mb-10">
            <InputText
              name="username"
              control={control}
              fieldError={errors.username}
              label={messages.username}
              required
              autoFocus
              size="small"
              fullWidth
            />
          </div>
          <div className="mb-10">
            <InputPassword
              name="password"
              control={control}
              fieldError={errors.password}
              label={messages.password}
              required
              size="small"
              fullWidth
            />
          </div>
          <Button
            type="submit"
            label={messages.logIn}
            variant="contained"
            size="large"
          />
          <div className="flex items-center justify-center mt-8">
            <MUILink
              component={RouterLink}
              to={AppRoutes.recoveryUsername()}
              underline="hover"
            >
              {messages.forgotUsername}
            </MUILink>
            <SignInDivider />
            <MUILink
              underline="hover"
              href={Config.aws.userForgotPasswordUrl}
            >
              {messages.forgotPassword}
            </MUILink>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};
