import { Dialog, IconButton, Typography } from '@mui/material';
import { messages } from 'features/payments/messages';
import { Button } from 'components/Button';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';

interface PaymentFailedProps {
  onSubmit: () => void;
  onClose: () => void;
}

/* TODO: Create dialog component */
export const PaymentFailed = ({ onSubmit, onClose }: PaymentFailedProps) => (
  <Dialog open>
    <div className="pt-6 pb-8 px-10 flex flex-col justify-center items-center text-center min-w-[440px] relative">
      <div className="mb-6">
        <CheckCircleOutlinedIcon
          className="text-[56px] w-[56px]"
          color="error"
          onSubmit={onClose}
        />
      </div>

      <div className="absolute top-5 right-5">
        <IconButton
          color="default"
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </div>

      <Typography
        className="mb-3"
        variant="h5"
      >
        {messages.failed.title}
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        whiteSpace="pre-wrap"
      >
        {messages.failed.body}
      </Typography>

      <div className="mt-8 flex flex-col">
        <Button
          type="button"
          variant="contained"
          color="primary"
          label={messages.failed.submit}
          onClick={onSubmit}
          size="large"
        />

        <Button
          className="mt-5"
          type="button"
          variant="text"
          color="primary"
          label={messages.failed.close}
          onClick={onClose}
          size="large"
        />
      </div>
    </div>
  </Dialog>
);
