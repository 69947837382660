import { Box as MuiBox } from '@mui/material';
import { Navbar } from 'components/Navbar';
import { Footer } from 'components/Footer';
import { Fragment, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { RoleGuard } from 'components/RoleGuard';
import { ProfileProvider } from 'common/profile';
import { CookieBanner } from 'components/CookieBanner';
import { Payments } from 'features/payments/Payments';
import { palette } from 'theme';

interface LayoutProps {
  children?: ReactNode;
  isPrivate?: boolean;
}

export const Layout = ({ children, isPrivate }: LayoutProps) => {
  const Wrapper = isPrivate ? ProfileProvider : Fragment;

  return (
    <Wrapper>
      <div className="flex min-h-full flex-col">
        <Navbar />
        <MuiBox
          className="flex-1"
          bgcolor={palette.custom.background.default}
        >
          <Breadcrumbs />

          {isPrivate && <Payments />}

          {children}
          {isPrivate ? (
            <RoleGuard>
              <Outlet />
            </RoleGuard>
          ) : (
            <Outlet />
          )}
        </MuiBox>
        <Footer />
      </div>
      {isPrivate && <CookieBanner />}
    </Wrapper>
  );
};
