import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'utils/axios-base-query';
import { getCookieByName } from 'utils/get-cookie-by-name';
import { CSRF_TOKEN_NAME } from 'common/auth/constants';
import { ProfileEntity } from './types';

export const profileApi = createApi({
  reducerPath: 'profile',
  tagTypes: ['profile'],
  baseQuery: axiosBaseQuery({ baseUrl: '/' }),
  endpoints: (build) => ({
    profileMe: build.query<ProfileEntity, void>({
      query: () => ({
        url: 'profile/me',
      }),
    }),
    acceptTerms: build.mutation<void, void>({
      query: () => ({
        url: 'accept_terms',
        method: 'POST',
        headers: {
          'X-CSRFTOKEN': getCookieByName(CSRF_TOKEN_NAME),
        },
      }),
    }),
  }),
});

export const { useProfileMeQuery: useProfileApiFetchMe } = profileApi;

export const { useAcceptTermsMutation: useAcceptTerms } = profileApi;
