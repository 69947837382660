import React from 'react';
import classNames from 'classnames';
import {
  TableHead as MUITableHead,
  TableRow as MUITableRow,
  TableHeadProps as MUITableHeadProps,
  TableRowProps as MUITableRowProps,
  TableCellProps as MUITableCellProps,
} from '@mui/material';
import { flexRender, HeaderGroup } from '@tanstack/react-table';
import { TableCellWrapper } from '../TableCells/components/TableCellWrapper';

export interface DefaultTableHeadProps {
  tableHeadProps?: MUITableHeadProps;
  tableRowProps?: MUITableRowProps;
  tableCellProps?: MUITableCellProps;
  classes?: {
    firstColumnStickyClassName?: string;
    lastColumnStickyClassName?: string;
    defaultColumnClassName?: string;
  };
}

interface TableHeadProps<TRowData> extends DefaultTableHeadProps {
  groups: HeaderGroup<TRowData>[];
  stickyOptions: {
    stickyFirstColumn: boolean;
    stickyLastColumn: boolean;
  };
  cellHeight?: string | number;
}

const defaultClasses = {
  firstColumnStickyClassName: 'sticky left-0 !z-30',
  lastColumnStickyClassName: 'sticky right-0',
  defaultColumnClassName: '!z-20',
};

export const TableHead = <TRowData,>({
  groups,
  cellHeight,
  tableHeadProps,
  tableRowProps,
  tableCellProps,
  classes = {},
  stickyOptions: { stickyFirstColumn, stickyLastColumn },
}: TableHeadProps<TRowData>) => {
  const { firstColumnStickyClassName, lastColumnStickyClassName, defaultColumnClassName } = {
    ...defaultClasses,
    ...classes,
  };

  return (
    <MUITableHead {...tableHeadProps}>
      {groups.map((group) => (
        <MUITableRow
          {...tableRowProps}
          key={group.id}
        >
          {group.headers.map((header) => {
            const isFirstColumnSticky = stickyFirstColumn && header.index === 0;
            const isLastColumnSticky =
              stickyLastColumn && group.headers.length - 1 === header.index;

            const stickyClassName = classNames({
              [firstColumnStickyClassName]: isFirstColumnSticky,
              [lastColumnStickyClassName]: isLastColumnSticky,
            });

            const className = classNames(defaultColumnClassName, {
              [stickyClassName]: !header.column.columnDef.meta?.isActions,
            });

            return (
              <TableCellWrapper
                {...tableCellProps}
                key={header.id}
                className={className}
                colSpan={header.colSpan}
                minWidth={header.column.columnDef.minSize}
                height={cellHeight}
                shadow={isFirstColumnSticky}
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
              </TableCellWrapper>
            );
          })}
        </MUITableRow>
      ))}
    </MUITableHead>
  );
};
