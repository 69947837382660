import createPalette from '@mui/material/styles/createPalette';
import { green, lightBlue, orange, purple, red } from '@mui/material/colors';

/*
 * Additional custom colors
 */
const customColors = {
  link: { primary: '#0372CF' },
  button: { focus: '#DAD8E0' },
  background: { default: '#FDFDFD' },
};

/*
 * Default palette overrides
 */
const customPalette = {
  primary: {
    main: '#8859CB',
    dark: '#643CA6',
    light: '#976AD8',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: purple[500],
    dark: purple[700],
    light: purple[300],
    contrastText: '#FFFFFF',
  },
  text: {
    primary: '#323232',
    secondary: '#666666',
    disabled: '#A1A1A1',
  },
  action: {
    active: '#707070',
    hover: '#F3F3F3',
    selected: '#F1F1F1',
    focus: '#E0E0E0',
    disabled: '#A1A1A1',
    disabledBackground: '#DCDCDC',
  },
  error: {
    main: red[700],
    dark: red[800],
    light: red[400],
    contrastText: '#FFFFFF',
  },
  warning: {
    main: orange[800],
    dark: orange[900],
    light: orange[500],
    contrastText: '#FFFFFF',
  },
  info: {
    main: lightBlue[700],
    dark: lightBlue[900],
    light: lightBlue[500],
    contrastText: '#FFFFFF',
  },
  success: {
    main: green[700],
    dark: green[900],
    light: green[500],
    contrastText: '#DCDCDC',
  },
  common: {
    black: '#1B1B1B',
    white: '#FFFFFF',
  },
  background: {
    default: '#FFFFFF',
    paper: '#FFFFFF',
  },
  divider: '#E6E6E6',

  custom: customColors,
};

export const palette = createPalette(customPalette);

/*
 * For adding custom colors to palette and covering by TS
 */
declare module '@mui/material/styles' {
  interface Palette {
    custom: typeof customColors;
  }

  interface PaletteOptions {
    custom: typeof customColors;
  }
}
