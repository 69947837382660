import React from 'react';
import { Typography } from '@mui/material';
import { StudyAddDropdown } from './StudyAddDropdown';
import { messages } from '../messages';

export const StudiesTableHeader = () => (
  <div className="flex justify-between mt-8 mb-7">
    <Typography
      variant="h4"
      color="text.primary"
    >
      {messages.studies}
    </Typography>
    <StudyAddDropdown />
  </div>
);
