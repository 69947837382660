import React, { ReactNode, SyntheticEvent } from 'react';
import {
  Link as MuiLink,
  Menu as MuiMenu,
  MenuProps as MuiMenuProps,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material';

interface Action {
  title: string;
  Icon: ReactNode;
  key: string;
  link: string;
}

export type ActionsProps = Action[];
export type MenuProps = MuiMenuProps;
export type MenuItemProps = MuiMenuItemProps;

interface ActionsMenuProps {
  a11yId: string;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClick: (key: string) => (event: SyntheticEvent) => void;
  onClose: () => void;
  actions: ActionsProps;
  menuProps?: MenuProps;
  menuItemProps?: MenuItemProps;
}

export const ActionsMenu = ({
  a11yId,
  anchorEl,
  open,
  onClose,
  onClick,
  actions,
  menuProps,
  menuItemProps,
}: ActionsMenuProps) => (
  <MuiMenu
    elevation={8}
    classes={{
      paper: 'w-60 rounded-lg',
    }}
    MenuListProps={{
      'aria-labelledby': a11yId,
    }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...menuProps}
    id={a11yId}
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}
  >
    {actions.map(({ title, Icon, key, link }) => (
      <MuiLink
        key={key}
        color="text.primary"
        underline="none"
        href={link}
      >
        <MuiMenuItem
          {...menuItemProps}
          onClick={onClick(key)}
        >
          {Icon}
          {title}
        </MuiMenuItem>
      </MuiLink>
    ))}
  </MuiMenu>
);
