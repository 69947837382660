import React, { SyntheticEvent } from 'react';
import { IconButton as MUIIconButton, IconButtonProps as MUIIconButtonProps } from '@mui/material';
import { AscArrowSortIcon, DescArrowSortIcon, DefaultArrowSortIcon } from 'icons';

interface HeaderSortingProps extends MUIIconButtonProps {
  sorting: string | false;
  onToggleSort?: (event: SyntheticEvent) => void;
  a11ySortedBy: string;
}

const DEFAULT_SORTING = 'default';

export const HeaderSorting = ({
  sorting,
  onToggleSort,
  a11ySortedBy,
  ...props
}: HeaderSortingProps) => {
  const IconComponent = {
    asc: <AscArrowSortIcon />,
    desc: <DescArrowSortIcon />,
    default: <DefaultArrowSortIcon />,
  }[sorting || DEFAULT_SORTING];

  return (
    <MUIIconButton
      sx={{ padding: 0.5, marginLeft: 0.5, marginRight: -0.6 }}
      {...props}
      aria-label={`${a11ySortedBy} ${sorting}`}
      onClick={onToggleSort}
    >
      {IconComponent}
    </MUIIconButton>
  );
};
