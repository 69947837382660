import React, { ReactNode } from 'react';
import { TooltipProps as MUITooltipProps } from '@mui/material';
import classNames from 'classnames';
import { useTruncateText } from 'hooks';
import { TruncatedTextCell } from './TruncatedTextCell';
import { RenderTooltipCell } from './RenderTooltipCell';

export interface TruncatedTextTooltipCellProps extends Omit<MUITooltipProps, 'children' | 'title'> {
  children: ReactNode;
  popperClassName?: string;
  textClassName?: string;
}

export const TruncatedTextTooltipCell = ({
  children,
  popperClassName,
  textClassName,
  ...props
}: TruncatedTextTooltipCellProps) => {
  const { isTruncatedText, containerRef } = useTruncateText();

  return (
    <RenderTooltipCell
      {...props}
      disableHoverListener={!isTruncatedText}
      classes={{ popper: classNames('max-w-[240px]', popperClassName) }}
      title={children}
    >
      <TruncatedTextCell
        containerRef={containerRef}
        className={textClassName}
      >
        {children}
      </TruncatedTextCell>
    </RenderTooltipCell>
  );
};
