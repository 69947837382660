import { AppRoutes } from 'shared';
import { messages } from './messages';

export const NAVIGATION_OPTIONS = [
  {
    title: messages.studies,
    groups: [
      {
        title: messages.studyData,
        links: [
          { title: messages.viewEditableStudies, url: AppRoutes.viewEditableStudies() },
          { title: messages.viewAllStudies, url: AppRoutes.studyList() },
          { title: messages.viewStudySets, url: AppRoutes.viewStudySets() },
        ],
      },
      {
        title: messages.studyComponents,
        links: [{ title: messages.studyComponents, url: AppRoutes.studyComponents() }],
      },
      {
        title: messages.requiresPermission,
        links: [
          { title: messages.addStudy, url: AppRoutes.addStudy() },
          { title: messages.addStudySet, url: AppRoutes.addStudySet() },
        ],
      },
    ],
  },
  {
    title: messages.analysis,
    groups: [
      {
        title: messages.studyDataAnalysis,
        links: [
          { title: messages.graphingReproducibility, url: AppRoutes.graphingReproducibility() },
        ],
      },
      {
        title: messages.compoundActivities,
        links: [
          { title: messages.heatmap, url: AppRoutes.heatmap() },
          { title: messages.cluster, url: AppRoutes.cluster() },
        ],
      },
      {
        title: messages.computationalModeling,
        links: [
          { title: messages.pbpk, url: AppRoutes.pbpk() },
          // { title: messages.predictiveModeling, url: AppRoutes.predictiveModeling() },
        ],
      },
      // {
      //   title: messages.api,
      //   links: [{ title: messages.webApi, url: AppRoutes.webApi() }],
      // },
    ],
  },
  {
    title: messages.models,
    groups: [
      {
        title: messages.modelData,
        links: [
          { title: messages.viewExperimentalModels, url: AppRoutes.viewExperimentalModels() },
        ],
      },
      {
        title: messages.modelComponents,
        links: [
          { title: messages.viewMicrodevices, url: AppRoutes.viewMicrodevices() },
          { title: messages.viewMicrodeviceLocations, url: AppRoutes.viewMicrodeviceLocations() },
        ],
      },
      {
        title: messages.requiresPermission,
        links: [
          { title: messages.addExperimentalModel, url: AppRoutes.addExperimentalModel() },
          { title: messages.addMicrodevice, url: AppRoutes.addMicrodevice() },
        ],
      },
    ],
  },
  {
    title: messages.compounds,
    groups: [
      {
        title: messages.compoundData,
        links: [
          { title: messages.viewChemicalData, url: AppRoutes.viewChemicalData() },
          { title: messages.viewBioactivities, url: AppRoutes.viewBioactivities() },
          { title: messages.viewDrugTrials, url: AppRoutes.viewDrugTrials() },
          { title: messages.viewAdverseEvents, url: AppRoutes.viewAdverseEvents() },
        ],
      },
      {
        title: messages.reports,
        links: [
          { title: messages.compareAdverseEvents, url: AppRoutes.compareAdverseEvents() },
          { title: messages.compoundReport, url: AppRoutes.compoundReport() },
        ],
      },
      {
        title: messages.requiresPermission,
        links: [
          { title: messages.addCompound, url: AppRoutes.addCompound() },
          { title: messages.addMultipleCompound, url: AppRoutes.addMultipleCompound() },
        ],
      },
    ],
  },
  {
    title: messages.diseases,
    groups: [
      {
        links: [
          { title: messages.covidPortal, url: AppRoutes.covidPortal() },
          { title: messages.viewOtherDiseases, url: AppRoutes.viewOtherDiseases() },
        ],
      },
    ],
  },
  {
    title: messages.cells,
    groups: [
      {
        title: messages.cellData,
        links: [
          { title: messages.viewCellTypes, url: AppRoutes.viewCellTypes() },
          { title: messages.viewCellOrigins, url: AppRoutes.viewCellOrigins() },
          { title: messages.viewCellSamples, url: AppRoutes.viewCellSamples() },
        ],
      },
      {
        title: messages.requiresPermission,
        links: [
          { title: messages.addCellType, url: AppRoutes.addCellType() },
          { title: messages.addCellOrigin, url: AppRoutes.addCellOrigin() },
          { title: messages.addCellSample, url: AppRoutes.addCellSample() },
        ],
      },
    ],
  },
];

export enum UsernameMenuItems {
  EDIT_PERMISSIONS = 'editPermission',
  LOG_OUT = 'logOut',
  CHANGE_PASSWORD = 'Change Password',
}

export const USER_NAME_MENU_LABELS: Record<UsernameMenuItems, string> = {
  [UsernameMenuItems.EDIT_PERMISSIONS]: 'Edit Group Permissions',
  [UsernameMenuItems.LOG_OUT]: 'Log out',
  [UsernameMenuItems.CHANGE_PASSWORD]: 'Change Password',
};
