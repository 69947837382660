import React, { HTMLAttributes } from 'react';
import { Checkbox as MuiCheckbox } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { highlightSearchedText } from 'utils/highlight-searched-text';
import sanitize from 'xss';

interface CheckboxOptionProps {
  option: string;
  inputValue: string;
  selected: boolean;
  optionProps: HTMLAttributes<HTMLLIElement>;
}

export const AutocompleteCheckboxOption = ({
  optionProps,
  selected,
  option,
  inputValue,
}: CheckboxOptionProps) => {
  const Icon = <CheckBoxOutlineBlank fontSize="small" />;
  const CheckedIcon = <CheckBox fontSize="small" />;

  return (
    <li {...optionProps}>
      <MuiCheckbox
        icon={Icon}
        checkedIcon={CheckedIcon}
        style={{ marginRight: 8 }}
        checked={selected}
      />
      <span
        className="line-clamp-2 pr-2"
        dangerouslySetInnerHTML={{ __html: sanitize(highlightSearchedText(option, inputValue)) }}
      />
    </li>
  );
};
