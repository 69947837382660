import React from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import {
  TableBody as MuiTableBody,
  TableBodyProps as MuiTableBodyProps,
  TableRow as MuiTableRow,
  TableRowProps as MuiTableRowProps,
  TableCellProps as MuiTableCellProps,
} from '@mui/material';
import classNames from 'classnames';
import { TableCellWrapper } from '../TableCells/components/TableCellWrapper';
import { TableBodyLoading } from './components/TableBodyLoading';
import { TableBodyEmpty } from './components/TableBodyEmpty';

export interface DefaultTableBodyProps {
  tableBodyProps?: MuiTableBodyProps;
  tableRowProps?: MuiTableRowProps;
  tableCellProps?: MuiTableCellProps;
  classes?: {
    oddRowClassName?: string;
    evenRowClassName?: string;
    firstColumnStickyClassName?: string;
    lastColumnStickyClassName?: string;
  };
}

interface TableBodyProps<TRowData> extends DefaultTableBodyProps {
  rows: Row<TRowData>[];
  isLoading: boolean;
  columnsCount: number;
  stickyOptions: {
    stickyFirstColumn: boolean;
    stickyLastColumn: boolean;
  };
  cellHeight?: string | number;
  anchorActionsClassName: string;
}

const defaultClasses = {
  oddRowClassName: 'bg-white',
  evenRowClassName: 'bg-zinc-50',
  firstColumnStickyClassName: 'sticky left-0 z-10',
  lastColumnStickyClassName: 'sticky right-0',
};

export const TableBody = <TRowData,>({
  rows,
  columnsCount,
  isLoading,
  cellHeight,
  tableBodyProps,
  tableRowProps,
  tableCellProps,
  anchorActionsClassName,
  classes = {},
  stickyOptions: { stickyFirstColumn, stickyLastColumn },
}: TableBodyProps<TRowData>) => {
  const {
    oddRowClassName,
    evenRowClassName,
    firstColumnStickyClassName,
    lastColumnStickyClassName,
  } = { ...defaultClasses, ...classes };

  if (isLoading)
    return (
      <TableBodyLoading
        {...tableBodyProps}
        colSpan={columnsCount}
      />
    );

  if (!rows.length)
    return (
      <TableBodyEmpty
        {...tableBodyProps}
        colSpan={columnsCount}
      />
    );

  return (
    <MuiTableBody {...tableBodyProps}>
      {rows.map((row, rowIndex) => {
        const isOddRow = rowIndex % 2 === 0;
        const backgroundClassName = isOddRow ? oddRowClassName : evenRowClassName;

        return (
          <MuiTableRow
            {...tableRowProps}
            key={row.id}
            classes={{ root: backgroundClassName }}
          >
            {row.getVisibleCells().map((cell, i) => {
              const isFirstColumnSticky = stickyFirstColumn && i === 0;
              const isLastColumnSticky = stickyLastColumn && row.getVisibleCells().length - 1 === i;

              const className = classNames({
                [firstColumnStickyClassName]: isFirstColumnSticky,
                [lastColumnStickyClassName]: isLastColumnSticky,
                [backgroundClassName]: !cell.column.columnDef.meta?.isActions,
              });

              return (
                <TableCellWrapper
                  {...tableCellProps}
                  key={cell.id}
                  className={className}
                  align={cell.column.columnDef.meta?.alignCell}
                  minWidth={cell.column.columnDef.minSize}
                  height={cellHeight}
                  shadow={isFirstColumnSticky}
                >
                  {flexRender(cell.column.columnDef.cell, {
                    ...cell.getContext(),
                    anchorActionsClassName,
                  })}
                </TableCellWrapper>
              );
            })}
          </MuiTableRow>
        );
      })}
    </MuiTableBody>
  );
};
