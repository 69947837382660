export class StorageService {
  private static localStorageService: StorageService;

  private static sessionStorageService: StorageService;

  public static getLocalStorageService(): StorageService {
    if (!this.localStorageService) {
      this.localStorageService = new StorageService(window.localStorage);
    }

    return this.localStorageService;
  }

  public static getSessionStorageService(): StorageService {
    if (!this.sessionStorageService) {
      this.sessionStorageService = new StorageService(window.sessionStorage);
    }

    return this.sessionStorageService;
  }

  private readonly storage: Storage;

  private constructor(storage: Storage) {
    this.storage = storage;
  }

  public set(key: string, value: any): void {
    this.storage.setItem(key, JSON.stringify(value));
  }

  public get(key: string): any {
    let value = this.storage.getItem(key);

    try {
      value = value ? JSON.parse(value) : null;
    } catch (err) {
      throw new Error('Something went wrong');
    }

    return value;
  }

  public remove(key: string): void {
    this.storage.removeItem(key);
  }
}
