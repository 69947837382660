import { SelectOption } from 'components/Select';

export enum SignUpTrialStep {
  ORGANIZATION_INFO = 1,
  ACCOUNT_INFO = 2,
  COMPLETE = 3,
}

export const SIGN_UP_MAX_STEP = 2;

export const SIGN_UP_ORGANIZATION_TYPES: SelectOption[] = [
  { label: 'Academic', value: 'academic' },
  { label: 'Small Business (0-50)', value: 'small_business' },
  { label: 'GOV', value: 'gov' },
  { label: 'NGO', value: 'ngo' },
];

export enum SignUpQueryKeys {
  CONFIRMATION_CODE = 'confirmation_code',
  USER_NAME = 'user_name',
}
