import { Link as MUILink, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { AppRoutes } from 'shared';
import { messages } from '../messages';

export const SignInHeader = () => {
  return (
    <>
      <Typography variant="h4">{messages.logIn}</Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ marginTop: '0.25rem' }}
      >
        {`${messages.needAccount} `}
        <MUILink
          component={RouterLink}
          to={AppRoutes.signUp()}
          underline="hover"
        >
          {messages.signUp}
        </MUILink>
      </Typography>
    </>
  );
};
