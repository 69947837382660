export const messages = {
  a11yTable: 'Table',
  a11yRowsPerPage: 'Rows per page',
  a11yFirstPage: 'First Page',
  a11yPreviousPage: 'Previous Page',
  a11yNextPage: 'Next Page',
  a11yLastPage: 'Last Page',

  first: 'First',
  last: 'Last',
};
