import React from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import { FormControl, FormControlProps, TextFieldProps } from '@mui/material';
import { SearchInputStyled } from './styles/SearchInput.styled';

export type SearchInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  name: string;
  label: string;
  placeholder?: string;
  controlProps?: FormControlProps;
  inputProps?: TextFieldProps;
};

export const SearchInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  label,
  placeholder,
  controlProps,
  inputProps,
  control: _control,
}: SearchInputProps<TFieldValues, TName>) => {
  const { control } = useFormContext();

  return (
    <Controller
      key={name}
      name={name}
      control={control || _control}
      render={({ field }) => (
        <FormControl
          {...field}
          {...controlProps}
        >
          <SearchInputStyled
            {...inputProps}
            variant="outlined"
            id={`${label}-input`}
            label={label}
            placeholder={placeholder}
          />
        </FormControl>
      )}
    />
  );
};
