import { omit } from 'lodash';
import qs, { ParseOptions, StringifyOptions } from 'query-string';

const DEFAULT_STRINGIFY_OPTIONS = {
  skipNull: true,
  skipEmptyString: true,
  arrayFormat: 'bracket',
} as StringifyOptions;

const DEFAULT_PARSE_OPTIONS = {
  parseNumbers: true,
  parseBooleans: true,
  arrayFormat: 'bracket',
} as ParseOptions;

interface QueryParams {
  [key: string]: boolean | number | string | string[] | undefined;
}

export const stringifySearchParams = (searchParams: QueryParams, options?: StringifyOptions) =>
  qs.stringify(searchParams, {
    ...DEFAULT_STRINGIFY_OPTIONS,
    ...options,
  });

export const parseSearchParams = (searchParams: URLSearchParams, options?: ParseOptions) =>
  qs.parse(searchParams.toString(), {
    ...DEFAULT_PARSE_OPTIONS,
    ...options,
  });

export const updateSearchParams = <Query>(
  newParams: Query,
  searchParams: URLSearchParams,
  stringifyOptions?: StringifyOptions,
  parseOptions?: ParseOptions
) => {
  const query = parseSearchParams(searchParams, parseOptions);

  return qs.stringify(
    { ...query, ...newParams },
    {
      ...DEFAULT_STRINGIFY_OPTIONS,
      ...stringifyOptions,
    }
  );
};

export const removeSearchParams = (
  paramsToRemove: string[],
  searchParams: URLSearchParams,
  stringifyOptions?: StringifyOptions,
  parseOptions?: ParseOptions
) => {
  const query = parseSearchParams(searchParams, parseOptions);

  return qs.stringify(omit(query, paramsToRemove), {
    ...DEFAULT_STRINGIFY_OPTIONS,
    ...stringifyOptions,
  });
};
