import React, { useState, ReactNode } from 'react';
import { Tooltip as MUITooltip, TooltipProps as MUITooltipProps } from '@mui/material';

export interface RenderTooltipCellProps extends Omit<MUITooltipProps, 'children'> {
  children: ReactNode;
  containerClassName?: string;
}

export const RenderTooltipCell = ({
  children,
  containerClassName,
  ...props
}: RenderTooltipCellProps) => {
  const [renderTooltip, setRenderTooltip] = useState(false);

  const handleMouseEnter = () => !renderTooltip && setRenderTooltip(true);

  return (
    <span
      className={containerClassName}
      onMouseEnter={handleMouseEnter}
    >
      {renderTooltip ? (
        <MUITooltip {...props}>
          <span>{children}</span>
        </MUITooltip>
      ) : (
        children
      )}
    </span>
  );
};
