import React, { useState, MouseEvent, SyntheticEvent } from 'react';
import { CellContext } from '@tanstack/react-table';
import { ActionsButton, ActionButtonProps } from './components/ActionsButton';
import { ActionsMenu, ActionsProps, MenuProps, MenuItemProps } from './components/ActionsMenu';

interface TableCellActionsProps<TColumn, TValue> {
  actions: ActionsProps;
  context: CellContext<TColumn, TValue> & { anchorActionsClassName?: string };
  callback?: (event: SyntheticEvent, key: string, context: CellContext<TColumn, TValue>) => void;
  buttonProps?: ActionButtonProps;
  menuProps?: MenuProps;
  menuItemProps?: MenuItemProps;
}

export const TableCellActions = <TColumn, TValue>({
  actions,
  context,
  callback,
  buttonProps,
  menuProps,
  menuItemProps,
}: TableCellActionsProps<TColumn, TValue>) => {
  const {
    cell: { id },
    anchorActionsClassName,
  } = context;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleItemClick = (key: string) => (event: SyntheticEvent) => {
    if (callback) {
      callback(event, key, context);
    }

    handleClose();
  };

  const a11yId = `a11y-${anchorActionsClassName}-menu-${id}`;

  return (
    <>
      <ActionsButton
        {...buttonProps}
        isOpen={open}
        onClick={handleOpen}
        anchorClassName={anchorActionsClassName}
      />
      <ActionsMenu
        {...menuProps}
        a11yId={a11yId}
        anchorEl={anchorEl}
        open={open}
        onClick={handleItemClick}
        onClose={handleClose}
        actions={actions}
        menuItemProps={menuItemProps}
      />
    </>
  );
};
