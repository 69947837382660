import { FormProvider, useForm } from 'react-hook-form';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { yupResolver } from '@hookform/resolvers/yup';
import { signUpOrganizationInfoSchema, signUpAccountInfoSchema } from 'features/sign-up/schemas';
import { BaseSyntheticEvent, useState } from 'react';
import { SignUpOrganizationInfo } from 'features/sign-up/components/SignUpOrganizationInfo';
import { SignUpAccountInfo } from 'features/sign-up/components/SignUpAccountInfo';
import { SIGN_UP_ORGANIZATION_TYPES, SignUpTrialStep } from 'features/sign-up/constants';
import { SignUpComplete } from 'features/sign-up/components/SignUpComplete';
import { useAuth } from 'common/auth';
import { SubscriptionPlans } from 'shared';
import { AuthLayout } from '../../components/AuthLayout';

const SCHEMA_BY_STEPS = {
  [SignUpTrialStep.ORGANIZATION_INFO]: signUpOrganizationInfoSchema,
  [SignUpTrialStep.ACCOUNT_INFO]: signUpAccountInfoSchema,
  [SignUpTrialStep.COMPLETE]: signUpOrganizationInfoSchema,
};

export interface SignUpTrialForm {
  trial: boolean;
  organizationType: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;

  institution: string;
  organization: string;
}

type SignUpProps = {
  trial?: boolean;
};

export const SignUp = ({ trial = false }: SignUpProps) => {
  const {
    signUpState: { signUp },
  } = useAuth();
  const [step, setStep] = useState<SignUpTrialStep>(SignUpTrialStep.ORGANIZATION_INFO);
  const currentSchema = SCHEMA_BY_STEPS[step];

  const form = useForm<SignUpTrialForm>({
    // @ts-ignore
    resolver: yupResolver(currentSchema),
    defaultValues: {
      trial,
      organizationType: SIGN_UP_ORGANIZATION_TYPES[0].value,
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',

      institution: '',
      organization: '',
    },
  });

  const handleSubmit = (values: SignUpTrialForm, event?: BaseSyntheticEvent<object, any, any>) => {
    event?.preventDefault();
    const { trial: isTrial, ...formValues } = values;

    signUp({
      ...formValues,
      tariffPlan: isTrial ? SubscriptionPlans.TRIAL : SubscriptionPlans.LICENSE,
    })
      .then((isSuccess) => {
        if (isSuccess) {
          setStep(SignUpTrialStep.COMPLETE);
        }
      })
      .catch((error) => form.setError('username', { message: error?.data?.error || '' }));
  };

  const handleBack = () => setStep((prevState) => --prevState);

  return (
    <AuthLayout>
      <FormProvider {...form}>
        {step === SignUpTrialStep.ORGANIZATION_INFO && (
          <SignUpOrganizationInfo
            onSubmit={form.handleSubmit(() => {
              setStep(SignUpTrialStep.ACCOUNT_INFO);
            })}
          />
        )}

        {step === SignUpTrialStep.ACCOUNT_INFO && (
          <SignUpAccountInfo
            onClickBack={handleBack}
            onSubmit={form.handleSubmit(handleSubmit)}
          />
        )}

        {step === SignUpTrialStep.COMPLETE && (
          <SignUpComplete
            icon={
              <CheckCircleOutlineOutlinedIcon
                color="success"
                sx={{ width: '32px', height: '32px' }}
              />
            }
          />
        )}
      </FormProvider>
    </AuthLayout>
  );
};
