import { useFormContext, useWatch } from 'react-hook-form';
import { SyntheticEvent } from 'react';

export const useAutocomplete = (name: string) => {
  const { control, setValue } = useFormContext();

  const onChange = (value: string[]) => setValue(name, value, { shouldDirty: true });
  const values = useWatch({ name }) || [];

  const handleChange = (e: SyntheticEvent, newValue: string[]) => onChange(newValue);

  const handleReset = () => onChange([]);

  const handleDelete = (title: string) => () =>
    onChange(values.filter((option) => option !== title));

  return {
    control,
    values,
    setValue,
    onChange: handleChange,
    onReset: handleReset,
    onDelete: handleDelete,
  };
};
