import styled from '@emotion/styled';
import { palette } from 'theme';

export const LiStyled = styled.li<{ isActive?: boolean }>`
  padding: 0.5rem 1.5rem;
  letter-spacing: 0.15px;

  a {
    color: ${({ isActive }) => (isActive ? palette.primary.main : 'inherit')};

    &:hover {
      color: ${palette.primary.main};
    }
  }
`;
