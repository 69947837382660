import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'common/auth';
import { AppRoutes } from 'shared';

export const PublicRoute = () => {
  const { isAuthorized } = useAuth();

  if (isAuthorized) {
    return (
      <Navigate
        to={AppRoutes.root()}
        replace
      />
    );
  }

  return <Outlet />;
};
