import { TextField } from '@mui/material';
import styled from '@emotion/styled';
import { theme } from 'theme';

export const SearchInputStyled = styled(TextField)`
  // Default state
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.palette.primary.light};
  }

  // Hovered state
  & .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.palette.primary.main};
  }

  // Focused state
  & .MuiInputBase-root:focus-within .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.palette.primary.main};
  }
`;
